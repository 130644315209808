.frontPage-background-color {
    background-color: #FCFCFC;
}

/*-----------------------------------------
*  Front Page Header
-----------------------------------------*/

.frontPage {
    background-image: url("../img/front/bg-header-front-page.svg");
    background-repeat: no-repeat;
    background-size: auto;
    padding: 190px 0 100px;
    background-attachment: local;
    background-position: top;

    @media (max-width: 1280px) {
        padding: 150px 0 80px;
    }

    @media (max-width: 992px) {
        padding: 100px 0 60px;
        background-size: auto 50%;
    }

    @media (max-width: 769px) {
        padding: 100px 0 60px;
        background-position: -250px -70px;
    }

    .container {
        max-width: 1170px;
        padding: 0;
        display: flex;
        justify-content: space-between;

        @media (max-width: 1280px) {
            max-width: 90%;
        }

        @media (max-width: 992px) {
            flex-direction: column;
        }
    }
}

.frontPage-header {
    width: 42%;
    flex-direction: row;
    font-family: 'Montserrat';
    font-size: 80px;
    font-weight: 900;
    line-height: 96px;
    color: #323232;
    
    h1 {
        font-size: 60px;
        font-weight: 500;
        line-height: 53px;
        margin: 0;
    }

    @media (max-width: 1280px) {
        font-size: 60px;
        line-height: 60px;

        h1 {
            font-size: 40px;
            line-height: 40px;
        }
    }

    @media (max-width: 992px) {
        font-size: 45px;
        line-height: 45px;
        width: 70%;

        h1 {
            font-size: 30px;
            line-height: 30px;
        }
    }

    @media (max-width: 769px) {
        font-size: 32px;
        line-height: 30px;
        width: 100%;

        h1 {
            font-size: 20px;
            line-height: 24px;
        }
    }

    &--comparison {

        @media (max-width: 992px) {
            width: 100%;
        }
    }
}

.frontPage-header-text {
    font-family: 'Pt Sans';
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    color: #55595C;
    margin: 30px 0 60px;

    @media (max-width: 1280px) {
        margin: 30px 0 30px;
    }

    @media (max-width: 992px) {
        margin: 20px 0 20px;
    }
}

.frontPage-video-block {
    width: 53%;
    margin-top: 100px;

    @media (max-width: 1280px) {
        margin-top: 80px;
    }

    @media (max-width: 992px) {
        margin-top: 30px;
        width: 100%;
    }
}

/*-----------------------------------------
*  Front Page Stickers Block
-----------------------------------------*/

.frontPage-stickers {
    background-image: url("../img/front/bg-stickers-front-page.svg");
    background-repeat: no-repeat;
    background-size: auto;
    background-attachment: local;
    background-position: top;
    min-height: 1070px;

    @media (max-width: 992px) {
        background-size: auto 70%;
    }

    @media (max-width: 769px) {
        min-height: 800px;
        background-position: -480px top;
    }

    .container {
        max-width: 1170px;
        padding: 0;

        @media (max-width: 1280px) {
            max-width: 90%;
            padding-bottom: 100px;
        }

        @media (max-width: 992px) {
            padding-bottom: 60px;
        }

        @media (max-width: 769px) {
            padding-bottom: 40px;
        }
    }
}

.frontPage-stickers-header {
    padding: 165px 0 60px;
    font-family: 'Montserrat';
    font-size: 52px;
    font-weight: 900;
    line-height: 62px;
    color: #FFFFFF;

    @media (max-width: 992px) {
        padding: 100px 0 45px;
        font-size: 40px;
        line-height: 48px;
    }

    @media (max-width: 992px) {
        padding: 60px 0 25px;
        font-size: 32px;
    }
}

.frontPage-stickers-block {
    display: block;
    columns: 370px auto;
    column-gap: 30px;

    @media (max-width: 1280px) {
        column-gap: 25px;
    }

    @media (max-width: 992px) {
        columns: 334px auto;
    }
}

.frontPage-stickers-card {
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(117, 117, 117, 0.11);
    border-radius: 16px;
    margin-bottom: 30px;
    page-break-inside: avoid;
    break-inside: avoid;
    height: 265px;
    padding: 30px 30px 30px;
    font-family: 'Montserrat';
    font-weight: 900;
    font-size: 22px;
    line-height: 26px;
    color: #444444;

    @media (max-width: 1280px) {
        padding: 40px 25px 25px;
        margin-bottom: 25px;
    }

    @media (max-width: 769px) {
        height: auto;
        padding: 20px 20px 20px;
        font-size: 20px;
        line-height: 24px;
    }

    span {
        display: inline-block;
        padding: 20px 0 0;
        font-family: Pt Sans;
        color: #6A6A6A;
        font-weight: 400;
        font-size: 18px;

        @media (max-width: 1280px) {
            padding: 10px 0;
        }

        @media (max-width: 992px) {
            padding: 7px 0;
        }

        @media (max-width: 769px) {
            font-size: 16px;
        }
    }
}

/*-----------------------------------------
*  Front Page Provides Block
-----------------------------------------*/

.frontPage-provides {

    .container {
        max-width: 1170px;
        padding: 0;
        font-family: 'Montserrat';
        font-weight: 900;
        font-size: 52px;
        line-height: 62px;
        color: #444444;

        @media (max-width: 1280px) {
            max-width: 90%;
        }

        @media (max-width: 992px) {
            font-size: 40px;
            line-height: 50px;
        }

        @media (max-width: 769px) {
            font-size: 32px;
            line-height: 45px;
        }
    }
}

.frontPage-provides-block {
    padding: 60px 0 90px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (max-width: 1280px) {
        padding: 40px 0 60px;
    }

    @media (max-width: 992px) {
        padding: 30px 0 45px;
    }

    @media (max-width: 769px) {
        padding: 15px 0 30px;
        flex-direction: column;
    }
}

.frontPage-provides-post {
    margin-bottom: 60px;
    width: 48%;
    min-height: 60px;
    padding-left: 100px;
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #444444;
    position: relative;

    @media (max-width: 1280px) {
        margin-bottom: 35px;
        padding-left: 75px;
        min-height: 45px;
    }

    @media (max-width: 992px) {
        margin-bottom: 25px;
        font-size: 20px;
        line-height: 24px;
    }

    @media (max-width: 769px) {
        width: 100%;
        padding: 0 0 0 50px;
        margin-bottom: 15px;
        min-height: 35px;
        font-size: 18px;
        line-height: 22px;
    }

    span {
        display: inline-block;
        padding: 25px 0 0;
        font-family: Pt Sans;
        font-weight: 600;
        font-size: 20px;

        @media (max-width: 1200px) {
            padding: 10px 0;
        }

        @media (max-width: 992px) {
            padding: 7px 0;
            font-size: 18px;
        }

        @media (max-width: 769px) {
            padding: 7px 0;
            font-size: 16px;
        }
    }

    .provides-icon {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 60px;
        height: 55px;
        background-color: #4977ff;
        margin-bottom: 30px;
        border-radius: 10px;

        @media (max-width: 1280px) {
            width: 45px;
            height: 45px;
            margin-bottom: 20px;
        }

        @media (max-width: 767px) {
            width: 35px;
            height: 35px;
            margin-bottom: 10px;
            border-radius: 5px;
        }

       span.material-icons {
            font-family: 'Material Icons' !important;
            display: flex;
            white-space: nowrap;
            color: white;
            align-content: center;
            justify-content: center;
            align-items: center;
            padding: 5px;
            font-size: 45px;

            @media (max-width: 1280px) {
                font-size: 35px;
            }

            @media (max-width: 767px) {
                font-size: 25px;
            }
        }
    }
}

/*-----------------------------------------
*  Front Page Planning Block
-----------------------------------------*/

.frontPage-planning {
    background-image: url("../img/front/bg-planning-front-page.svg");
    background-repeat: no-repeat;
    background-size: auto;
    background-attachment: local;
    background-position: top;
    min-height: 605px;

    @media (max-width: 992px) {
        background-size: auto 85%;
    }

    @media (max-width: 769px) {
        min-height: 0;
        background: none;
        margin-bottom: 30px;
    }

    .container {
        max-width: 1170px;
        padding: 0;

        @media (max-width: 1280px) {
            max-width: 90%;
        }
    }
}

.frontPage-planning-block {
    max-width: 55%;
    font-family: 'Montserrat';
    font-weight: 900;
    font-size: 42px;
    line-height: 50px;
    color: #444444;
    padding-top: 150px;

    @media (max-width: 992px) {
        max-width: 60%;
        padding-top: 100px;
        font-size: 38px;
    }

    @media (max-width: 769px) {
        padding-top: 0;
        max-width: 100%;
        font-size: 30px;
        line-height: 40px;
    }
}

.frontPage-planning-text {
    font-family: Pt Sans;
    font-weight: 400;
    font-size: 20px;
    line-height: 29px;
    color: #6A6A6A;
    padding-top: 20px;

    a {
        display: inline-block;
        text-decoration-line: underline !important;
        font-family: 'Montserrat';
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #4B4EF7;
        padding-top: 30px;
    }

    @media (max-width: 992px) {
        font-size: 18px;
        line-height: 26px;
        padding-top: 15px;

        a {
            padding-top: 20px;
        }
    }

    @media (max-width: 769px) {
        font-size: 16px;
        line-height: 20px;
    }
}

/*-----------------------------------------
*  Front Page Reporting Block
-----------------------------------------*/

.frontPage-reporting {
    background-image: url("../img/front/bg-reporting-front-page.svg");
    background-repeat: no-repeat;
    background-size: auto;
    background-attachment: local;
    background-position: top;
    min-height: 885px;

    @media (max-width: 769px) {
        min-height: 800px;
        background-size: auto 100%;
        background-position: -300px top;
    }

    .container {
        max-width: 1170px;
        padding: 0;

        @media (max-width: 1280px) {
            max-width: 90%;
        }
    }
}

.frontPage-reporting-block {
    max-width: 70%;
    font-family: 'Montserrat';
    font-weight: 900;
    font-size: 42px;
    line-height: 50px;
    color: #ffffff;
    padding-top: 145px;

    @media (max-width: 1280px) {
        max-width: 75%;
        padding-top: 120px;
    }

    @media (max-width: 992px) {
        font-size: 40px;
        line-height: 46px;
        padding-top: 110px;
    }

    @media (max-width: 767px) {
        padding-top: 80px;
        font-size: 30px;
        line-height: 40px;
        max-width: 100%;
    }
}

.frontPage-reporting-text {
    font-family: 'Pt Sans';
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: #ffffff;
    padding: 30px 0 60px;

    @media (max-width: 1280px) {
        padding: 20px 0 40px;
    }

    @media (max-width: 992px) {
        padding: 15px 0 30px;
    }

    @media (max-width: 767px) {
        font-size: 14px;
        line-height: 20px;
        padding: 5px 0 20px;
    }
}

/*-----------------------------------------
*  Front Page Tools Block
-----------------------------------------*/

.frontPage-tools {
    background-image: url("../img/front/bg-tools-front-page.svg");
    background-repeat: no-repeat;
    background-size: auto;
    background-attachment: local;
    background-position: top;
    min-height: 350px;

    @media (max-width: 992px) {
        background-size: auto 50%;
    }

    @media (max-width: 767px) {
        background: none;
    }

    .container {
        max-width: 1170px;
        padding: 0;

        @media (max-width: 1280px) {
            max-width: 90%;
        }
    }
}

.frontPage-tools-block {
    max-width: 50%;
    font-family: 'Montserrat';
    font-weight: 900;
    font-size: 42px;
    line-height: 50px;
    color: #444444;
    padding: 60px 0;

    @media (max-width: 1280px) {
        padding: 30px 0;
    }

    @media (max-width: 1280px) {
        padding: 0 0 30px;
    }

    @media (max-width: 767px) {
        max-width: 100%;
        font-size: 32px;
        line-height: 40px;
    }

    a {
        display: inline-block;
        text-decoration-line: underline !important;
        font-family: 'Montserrat';
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #4B4EF7;
        padding-left: 20px;
    }
}

.frontPage-tools-text {
    font-family: 'Pt Sans';
    font-weight: 400;
    font-size: 20px;
    line-height: 29px;
    padding: 30px 0 0;
    
    @media (max-width: 1280px) {
        padding: 15px 0 0;
    }

    @media (max-width: 992px) {
        font-size: 18px;
        line-height: 26px;
    }

    ul {
        color: #5983FF;
        padding-left: 20px;
    }

    li {
        margin-bottom: 20px;

        @media (max-width: 1280px) {
            margin-bottom: 10px;
        }
    }

    span {
        color: #6A6A6A;
    }
}

/*-----------------------------------------
*  Front Page Tools Posts Block
-----------------------------------------*/

.frontPage-tools-posts {
    padding-top: 60px;
    
    @media (max-width: 1280px) {
        padding-top: 30px;
    }

    @media (max-width: 992px) {
        padding-top: 0px;
    }
    
    .container {
        max-width: 1200px;
        padding: 15px 0;
        
        @media (max-width: 1280px) {
            max-width: 90%;
        }
    }

    .owl-dots {
        position: relative;
        margin: 0 auto;
        width: 77%;
        display: flex;
        justify-content:space-around;
        padding: 0 60px;

        @media (max-width: 767px) {
            padding: 30px 0 0;
        }

        .owl-dot {
            display: block;
            width: 8%;
            height: 7px;
            background: #C4C4C4;
            border-radius: 9px;

            span {
                display: none;
            }

            &.active {
                background: #5983FF !important;
            }
        }
    }

    .owl-stage-outer {
        z-index: 2;
    }

    .owl-nav {
        position: relative;
        margin: 30px 45px;
        display: flex;

        @media (max-width: 767px) {
            display: none;
        }

        .owl-prev {
            position: absolute;
            width: 70px;
            height: 70px;
            border-radius: 50%;
            cursor: pointer;
            z-index: 1;
            background-image: url("../img/front/owl-button.svg");
            background-size: 100%;
            background-position: auto;
            box-shadow: 0px 4px 11px rgba(62, 62, 62, 0.14);
            opacity: 0.8;
            left: 0;
            transform: rotate(180deg);

            i {
                display: none;
            }

            &:hover {
                opacity: 1;
            }
        }

        .owl-next {
            position: absolute;
            width: 70px;
            height: 70px;
            border-radius: 50%;
            cursor: pointer;
            z-index: 1;
            background-image: url("../img/front/owl-button.svg");
            background-size: 100%;
            background-position: auto;
            box-shadow: 0px 4px 11px rgba(62, 62, 62, 0.14);
            opacity: 0.8;
            right: 0;
        
            i {
                display: none;
            }

            &:hover {
                opacity: 1;
            }
        }
    }
}

.frontPage-tools-posts-cards {
    display: flex;
    justify-content: space-between;
    width: 100%;
    color: #444444;
}

.frontPage-tools-posts-container {
    position: relative;
    width: 97,5%;
    height: 500px;
    margin: 10px 4px;
}

.frontPage-tools-posts-card {
    position: absolute;
    margin: 10px 10px;
    height: 98%;
    background: #FFFFFF;
    box-shadow: 0px 4px 11px rgba(62, 62, 62, 0.14);
    border-radius: 9px;

    img {
        padding: 5px;
    }
}

.frontPage-tools-posts-text {
    display: flex;
    flex-direction: column;
    font-family: 'Pt Sans';
    font-weight: 600;
    font-size: 20px;
    line-height: 29px;
    padding: 30px 30px 70px;
    color: #444444;

    @media (max-width: 992px) {
        padding: 20px 20px 20px;
        font-size: 18px;
        line-height: 26px;
    }

    span {
        display: flex;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-top: 15px;

        @media (max-width: 992px) {
            margin-top: 5px;
        }
    }

    a {
        display: flex;
        position: absolute;
        left: 30px;
        bottom: 30px;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #0073F4;

        @media (max-width: 992px) {
            left: 20px;
            bottom: 20px;
        }
    }
}

.frontPage-tools-posts-button {
    display: flex;
    justify-content:space-around;
    padding: 60px;

    @media (max-width: 1280px) {
        padding: 45px;
    }

    @media (max-width: 767px) {
        padding: 30px;
    }
}

/*-----------------------------------------
*  Front Page Stories Block
-----------------------------------------*/

.frontPage-stories {
    .container {
        max-width: 1200px;
        padding: 0 0 60px;

        @media (max-width: 1280px) {
            max-width: 90%;
        }
    }
}

.frontPage-stories-title {
    max-width: 900px;
    font-family: 'Montserrat';
    font-weight: 900;
    font-size: 42px;
    line-height: 50px;
    color: #444444;
    padding: 60px 15px 45px;
    
    @media (max-width: 1280px) {
        font-size: 36px;
        padding: 45px 15px 30px;
    }

    @media (max-width: 992px) {
        font-size: 32px;
        line-height: 40px;
        padding: 30px 15px 20px;
    }

    span {
        display: inline-block;
        margin-top: 30px;
        font-family: 'Pt Sans';
        font-weight: 400;
        font-size: 20px;
        line-height: 29px;
        color: #6A6A6A;

        @media (max-width: 1280px) {
            margin-top: 20px;
        }

        @media (max-width: 992px) {
            margin-top: 10px;
            font-size: 18px;
            line-height: 26px;
        }
    }
}

.frontPage-stories-cards {
    display: flex;
    justify-content: space-between;
    position: relative;

    &.owl-carousel {
        padding: 0 10px;
    }
    
    .owl-item {
        padding: 15px 15px;
    }

    .owl-dots {
        width: 77%;
        display: flex;
        justify-content: space-around;
        padding: 30px 0 0;
        margin: auto;
        z-index: 2;

        .owl-dot {
            display: block;
            width: 8%;
            height: 7px;
            background: #C4C4C4;
            border-radius: 9px;

            span {
                display: none;
            }

            &.active {
                background: #5983FF !important;
            }
        }
    }

    .owl-stage-outer {
        z-index: 2;
    }

    .owl-nav {

        @media (max-width: 767px) {
            display: none;
        }
    
        .owl-prev {
            position: absolute;
            width: 70px;
            height: 70px;
            border-radius: 50%;
            cursor: pointer;
            z-index: 2;
            background-image: url("../img/front/owl-button.svg");
            background-size: auto;
            background-position: auto;
            box-shadow: 0px 4px 11px rgba(62, 62, 62, 0.14);
            opacity: 0.8;
            top: 350px;
            left: -15px;
            transform: rotate(180deg);

            i {
                display: none;
            }

            &:hover {
                opacity: 1;
            }
        }

        .owl-next {
            position: absolute;
            width: 70px;
            height: 70px;
            border-radius: 50%;
            cursor: pointer;
            z-index: 2;
            background-image: url("../img/front/owl-button.svg");
            background-size: auto;
            background-position: auto;
            box-shadow: 0px 4px 11px rgba(62, 62, 62, 0.14);
            opacity: 0.8;
            top: 350px;
            right: -15px;
        
            i {
                display: none;
            }

            &:hover {
                opacity: 1;
            }
        }
    }
}

.frontPage-stories-card {
    position: relative;
    background: #FFFFFF;
    box-shadow: 0px 4px 11px rgba(62, 62, 62, 0.14);
    border-radius: 20px;
    padding: 35px;
    height: 700px;

    img {
        border-radius: 10px;
        width: 100%;    
    }

    @media (max-width: 1280px) {
        padding: 25px;
    }

    @media (max-width: 767px) {
        height: 500px;   
        min-width: 250px;   
    }
}

.frontPage-stories-card-text {
    font-family: 'Montserrat';
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
    color: #444444;
    margin-top: 50px;
    
    @media (max-width: 1280px) {
        font-size: 22px;
    }

    @media (max-width: 767px) {
        margin-top: 10px;
        font-size: 20px;
        line-height: 24px;
    }

    span {
        display: inline-block;
        margin-top: 20px;
        font-family: 'Pt Sans';
        font-size: 16px;
        line-height: 23px;
        font-weight: 600;

        @media (max-width: 767px) {
            margin-top: 10px;
            font-size: 14px;
        }
    }

    h2 {
        display: inline-block;
        margin-top: 20px;
        font-weight: 300;
        font-size: 18px;
        line-height: 22px;
        color: #0073F4;

        @media (max-width: 767px) {
            margin-top: 10px;
            font-size: 16px;
        }
    }
}

/*-----------------------------------------
*  Footer Component
-----------------------------------------*/

.footer-nav-bar {
    padding: 30px 40px 0;
    width: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px 10px 0 0;
    margin: 0;
    box-shadow: 0px 4px 14px rgba(117, 117, 117, 0.15);

    @media (max-width: 1280px) {
        padding: 20px 30px 0;
    }
}

.footer-nav-bar-content-center {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 0 25px;

    .footer-nav-bar-social-icons{
        margin: 0;
    }

    @media (max-width: 1280px) {
        flex-direction: column;
    }
}

.footer-nav-bar-brand {

    img {
        height: 30px;
    }
}

.footer-nav-bar-links {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;

    @media (max-width: 1280px) {
        padding: 10px 8px;
    }

    li {
        list-style-type: none;
        padding: 0 12px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        align-items: center;
        list-style-type: none;
        text-align: center;

        @media (max-width: 1280px) {
            padding: 0 8px;
            font-size: 16px;
            line-height: 20px;
        }

        @media (max-width: 769px) {
            padding: 5px;
            text-align: center;
        }
    
        a {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: #2E2D2D;

            @media (max-width: 769px) {
                justify-content: space-around;
            }
        }
    }

    @media (max-width: 769px) {
        flex-direction: column;

    }
}

.footer-nav-bar-social-icons {
    margin: 15px 0;
    height: 30px;

    img {
        @media (max-width: 992px) {
            height: 25px;
            width: 25px;
        }
    }

    @media (max-width: 770px) {
        text-align: center;
    }
}

.social-icons {
    margin: 5px;
    padding: 0;

    li {
        display: inline-block;
        vertical-align: middle;

        a {
            display: block;
            padding: 0;
            color: #7f7c97;
            width: 30px;
            height: 30px;
            border: 1px dashed #e5e8ed;
            text-align: center;
            line-height: 30px;
            border-radius: 0;
            background: transparent;
            -webkit-transition: ease all 0.3s;
            transition: ease all 0.3s;

            &:hover {
                color: #323232;
                background: #ffffff;
                box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
            }
        }
    }
}

/*-----------------------------------------
*  Footer Component Front Page
-----------------------------------------*/

.footer-nav-bar-content-center-front {
    display: flex;
    width: 100%;
    margin-bottom: 30px;

    @media (max-width: 1280px) {
        margin-bottom: 20px;
    }

    @media (max-width: 992px) {
        margin-bottom: 10px;
    }

    @media (max-width: 770px) {
        flex-direction: column;
        align-items: center;
        justify-content: space-around
    }
}

.footer-nav-bar-brand-container-front {
    margin: 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @media (max-width: 770px) {
        order: 2;
    }
}

.footer-nav-bar-brand-front {
    margin-top: 20px;

    img {
        height: 30px;
    }

    @media (max-width: 992px) {
        margin-top: 15px;
        text-align: left;
    }

    @media (max-width: 770px) {
        margin-top: 10px;
        text-align: center;
    }
}

.footer-nav-bar-menu-container-front {
    display: flex;
    flex-direction: row;
    width: 80%;
    justify-content: space-between;
    font-family: Montserrat;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #2E2D2D;

    @media (max-width: 1280px) {
        font-size: 16px;
        line-height: 20px;
    }

    @media (max-width: 992px) {
        font-size: 14px;
        line-height: 16px;
    }

    @media (max-width: 769px) {
        flex-direction: column;
        width: 60%;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 10px 0;

        @media (max-width: 769px) {
            margin: 10px 0 0;
        }
    
        a {
            color: #2E2D2D;
        }
    }

    li {
        margin: 10px 0 0;

        a {
            color: #878787 !important;
        }

        @media (max-width: 769px) {
            display: none;
        }
    }
}

.footer-nav-bar-links-container-front {
    width: 25%;
    padding: 0 30px;

    @media (max-width: 1280px) {
        padding: 0 15px;
    }

    @media (max-width: 769px) {
        width: 100%;
        text-align: center;
    }
}

.footer-nav-bar-line {
    width: 100%;
    height: 1px;
    border: 1px solid #ECECEC;
}

.footer-nav-bar-copyrignt-container-front {
    height: 70px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 75%;
    font-family: Montserrat;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #878787;
    margin: 0 25px;

    @media (max-width: 1280px) {
        height: 50px;
    }

    @media (max-width: 992px) {
        min-height: 25px;
        height: auto;
        font-size: 12px;
        line-height: 14px;
        margin: 0 15px;
        width: 100%;
    }

    @media (max-width: 530px) {
        flex-direction: column;
        padding: 5px 0;
    }

    a {
        color: #878787;

        @media (max-width: 1200px) {
            padding: 10px 0;
        }

        @media (max-width: 530px) {
            padding: 0;
        }
    }
}

.busines-key-present-background-color {
    background-color: #FCFCFC;
}

/*-----------------------------------------
*  Front Page Header
-----------------------------------------*/

.busines-key {
    padding-bottom: 60px;
    padding-top: 60px;

    &:last-child {
        padding-bottom: 0px;
    }

    @media (max-width: 992px) {
        padding-bottom: 30px;
        padding-top: 30px;
    }

    .container {
        max-width: 1170px;
        padding: 0;
        font-family: 'Montserrat';
        font-weight: 900;
        font-size: 52px;
        line-height: 62px;
        color: #444444;

        @media (max-width: 1280px) {
            max-width: 90%;
        }

        @media (max-width: 992px) {
            font-size: 40px;
            line-height: 50px;
        }

        @media (max-width: 767px) {
            font-size: 32px;
            line-height: 45px;
        }
    }
}

.busines-key-header {
    h2 {
        font-family: Montserrat;
        font-size: 42px;
        font-weight: 900;
        line-height: 50px;
        letter-spacing: 0em;
        text-align: left;
        padding-bottom: 30px;

        @media (max-width: 767px) {
            font-size: 32px;
            padding-bottom: 20px;
        }
    }

    p {
        font-family: Pt Sans;
        font-size: 20px;
        font-weight: 400;
        line-height: 28px !important;
        letter-spacing: 0em;
        text-align: left;
    }
}

.busines-key-present-sections {
    display: flex;
    flex-direction: row;
    padding-bottom: 50px;
    position: sticky;
    text-align: center;
    z-index: 15;

    @media (max-width: 1200px) {
        flex-direction: column;
    }
}

.busines-key-present-sections-block-text {
    width: 100%;

    h1 {
        font-family: Montserrat;
        font-size: 42px;
        font-weight: 900;
        line-height: 50px;
        text-align: left;
        padding-bottom: 40px;
    }

    p {
        font-family: Pt Sans;
        font-size: 20px;
        font-weight: 400;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: left;
    }
}

.busines-key-present-sections-block-video {
    min-width: 60%;
    padding-left: 50px;

    @media (max-width: 1200px) {
        padding-left: 0px;
    }
}

.busines-key-present-quotes {
    width: 100%;
    border-radius: 15px;
    background-color: #4977FF;
    color: #ffffff;
    font-family: Pt Sans;
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    box-shadow: 0px 0px 12px rgba(170, 170, 170, 0.1);
    display: flex;
    flex-direction: row;

    @media (max-width: 767px) {
        flex-direction: column;
    }
}

.busines-key-present-quotes-animation, .owl-stage-outer-animation {
    animation: wipe-enter 2.5s 1;
}

@keyframes wipe-enter {
    0% {
        transform: scale(0, .025)
    }

    50% {
        transform: scale(1, 1)
    }
}

.busines-key-grid-section-post-animation {
    animation: translate 2.5s 1;
}

@keyframes translate {
    0% {
        transform: translate(2px, 0%);
    }

    50% {
        transform: translate(10px, 10%);
    }
}


.busines-key-cards-sections-card-animation:nth-of-type(odd), .busines-key-present-sections-block-video-animation {
    animation: goLeft 3s 1;
}

.busines-key-cards-sections-card-animation:nth-of-type(even), .busines-key-present-sections-block-text-animation {
    animation: goRight 3s 1;
}

@keyframes goLeft {
    0% {
        transform: translateX(40em);
    }

    50% {
        transform: translateX(0em);
    }
}

@keyframes goRight {
    0% {
        transform: translateX(-40em);
    }

    50% {
        transform: translateX(0em);
    }
}


.owl-next-animation, .owl-prev-animation {
    animation: scale 2s 1;
}

@keyframes scale {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

.accordion-animation {
    // animation: goDown 2.5s 1;
    animation: swing 2s 1;
}

@keyframes goDown {
    0% {
        transform: skewY(1.07rad);
    }

    50% {
        transform: skewY(1.07rad);
    }
}

@keyframes swing {
    15% {
        -webkit-transform: translateX(5px);
        transform: translateX(5px);
    }

    30% {
        -webkit-transform: translateX(-5px);
        transform: translateX(-5px);
    }

    50% {
        -webkit-transform: translateX(3px);
        transform: translateX(3px);
    }

    65% {
        -webkit-transform: translateX(-3px);
        transform: translateX(-3px);
    }

    80% {
        -webkit-transform: translateX(2px);
        transform: translateX(2px);
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

.busines-key-present-sections-block-quote {
    min-width: 50%;
    padding: 35px;

    @media (max-width: 767px) {
        padding: 15px;
    }
}

//////////////////////////
// Блок 2
//////////////////////////


.busines-key-grid-section {
    // padding: 60px 0 90px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;

    @media (max-width: 769px) {
        padding: 15px 0 0;
        flex-direction: column;
    }
}

.busines-key-grid-section-post {
    margin-bottom: 10px;
    width: 33%;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #444444;

    @media (max-width: 1200px) {
        width: 50%;
    }

    @media (max-width: 767px) {
        width: 100%;
    }

    .busines-key-grid-section-post-block {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    span.material-icons {
        font-family: 'Material Icons';
        white-space: nowrap;
        color: #4977FF;
        font-size: 35px;
        margin-bottom: 30px;
    }

        .count {
        padding-top: 30px;
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 300;
        line-height: 22px;
        letter-spacing: 0em;
        color: #4977FF;

        @media (max-width: 1280px) {
            padding-top: 15px;
        }

        @media (max-width: 767px) {
            padding-top: 10px;
        }
    }
}

//////////////////////
// БЛОК 3
//////////////////////

.busines-key-cards-sections {
    padding-top: 30px;
    position: sticky;

    @media (max-width: 767px) {
        padding-top: 0px;
    }
}

.busines-key-cards-sections-card {
    font-family: Pt Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    height: 350px;
    display: flex;
    background-color: #fff;
    box-shadow: 0px 4px 10px rgba(117, 117, 117, 0.11);
    border-radius: 16px;
    margin-bottom: 30px;

    &:last-child {
        margin-bottom: 0px;
    }

    &--carousel {
        height: 400px;
    }

    h4 {
        font-family: Montserrat;
        font-size: 22px;
        font-weight: 900;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: left;
    }

    @media (max-width: 1200px) {
        margin-bottom: 20px;
        height: auto;
    }
}

.busines-key-cards-sections-card-separation {
    display: flex;
    flex-direction: row;

    @media (max-width: 1200px) {
        flex-direction: column;
        align-items: center
    }
}

.busines-key-cards-sections-card-separation-block-image {
    width: 500px;

    @media (max-width: 1200px) {
        width: 100%;
    }

    .wrapImg {
        text-align: center;
        justify-content: center;
        align-items: center;
        height: 100%;
        align-content: center;
        width: inherit;

        img {
            border-radius: 16px;
            width: auto;
            height: 100%;
        }
    }
}

.busines-key-cards-sections-card-separation-block {
    width: 100%;
    font-family: Pt Sans;
    overflow: auto;

    @media (max-width: 767px) {
        width: 100%;
        padding-left: 20px;
    }

    .wrapImg {
        text-align: center;
        height: 100%;
        display: flex;
        justify-content: flex-end;

        img {
            border-radius: 16px;
            width: auto;
        }
    }

    .wrapText {
        padding: 50px 10px 30px 40px;

        @media (max-width: 767px) {
            padding: 25px 10px 20px 20px;
        }

        @media (max-width: 520px) {
            padding: 20px 10px 10px 10px;
        }
    }

    // &:first-child {
    //     padding-right: 30px;
    //     padding: 50px;
    // }
}

.busines-key-cards-sections-card-separation-block::-webkit-scrollbar {
    width: 5px;
    background-color: #f9f9fd;
}

.busines-key-cards-sections-card-separation-block::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #4977FF;
}

.busines-key-cards-sections-card-separation-block::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    background-color: #f9f9fd;
}

////////////
// БЛОК 4
////////////

.busines-key-accordion {
    p {
        font-family: PT Sans;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        padding: 40px 30px;

        @media (max-width: 767px) {
            padding: 30px 20px;
        }
    }
}

.accordion {
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(117, 117, 117, 0.1);
    border-radius: 12px;
    font-family: Montserrat;
    font-size: 22px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0em;
    color: #444444;
    cursor: pointer;
    padding: 30px;
    margin: 30px 0;
    width: 100%;
    border: none;
    text-align: left;
    transition: 0.4s;
    outline: none !important;
    position: relative;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    div {
        width: 95%;
        font-family: Montserrat;
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0em;
        color: #444;
    }

    
    .header-with-icons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        span.material-icons {
            font-family: 'Material Icons' !important;
            line-height: 0;
            display: flex;
            white-space: nowrap;
            align-content: center;
            justify-content: center;
            align-items: center;
            font-size: 45px;
            color: #4977ff;
            padding: 0;
            padding-right: 45px;
            box-shadow:#444;

            @media (max-width: 900px) {
                padding-right: 20px;
            }
        }

        h3 {
            font-family: Montserrat;
            font-size: 20px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0em;
            color: #444;
            cursor: pointer;
            margin-bottom: 0 !important;
        }
    }
}

// .accordion.active,
// .accordion:hover {
//     background-color: #ffffffdb;
//     box-shadow: 0 0 3px 0 rgba(255,146,142, 0.5);
// }

.accordion:after {
    content: '\002B';
    color: #777;
    font-weight: bold;
    float: right;
    margin-left: 20px;
    box-shadow: 0 0 3px 0 rgba(73, 119, 255, 0.5);
    padding: 5px 10px;
    font-size: 30px;
    font-weight: 900;
    color: #4977FF;
    border-radius: 5px
}

.accordion.active:after {
    color: #FF928E;
    content: "\2212";
    box-shadow: 0 0 3px 0 rgba(255, 146, 142, 0.5);
}

.panel {
    padding: 0 18px;
    background-color: white;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out;
    margin-top: -35px;

    p {
        padding: 20px 20px 0;
    }

    @media (max-width: 767px) {
        padding: 0px;
    }
}

////////////
// БЛОК 4
////////////

.busines-key-cards-carousel {
    display: flex;
    justify-content: space-between;
    position: relative;

    &.owl-carousel {
        padding: 0 10px;
    }

    .owl-item {
        padding: 15px 15px;
    }

    .owl-dots {
        width: 77%;
        display: flex;
        justify-content: space-around;
        padding: 30px 0 0;
        margin: auto;
        z-index: 2;

        .owl-dot {
            display: block;
            width: 8%;
            height: 7px;
            background: #C4C4C4;
            border-radius: 9px;

            span {
                display: none;
            }

            &.active {
                background: #5983FF !important;
            }
        }
    }

    .owl-stage-outer {
        z-index: 2;
    }

    .owl-nav {

        @media (max-width: 767px) {
            display: none;
        }

        .owl-prev {
            position: absolute;
            width: 70px;
            height: 70px;
            border-radius: 50%;
            cursor: pointer;
            z-index: 2;
            background-image: url("../img/front/prev-button.svg");
            background-size: auto;
            background-position: auto;
            opacity: 0.8;
            top: 150px;
            left: -50px;

            i {
                display: none;
            }

            &:hover {
                opacity: 1;
            }
        }

        .owl-next {
            position: absolute;
            width: 70px;
            height: 70px;
            border-radius: 50%;
            cursor: pointer;
            z-index: 2;
            background-image: url("../img/front/next-button.svg");
            background-size: auto;
            background-position: auto;
            opacity: 0.8;
            top: 150px;
            right: -40px;

            i {
                display: none;
            }

            &:hover {
                opacity: 1;
            }
        }
    }
}

section.blog,
section.testimonials {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: #fff;

  .siniy-phone {
    background-color: #F4F8FB;
    width: 90%;
    border-radius: 15px;
  }

  .card {
    border: none;
    box-shadow: 0px 0px 7px rgb(173 173 173 / 50%);
    border-radius: 10px;

    .team-avtar {
      filter: grayscale(0%);

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        opacity: 0;
        transition: ease all 0.3s;
        -webkit-transition: ease all 0.3s;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }

      img {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        height: 100%;
      }
    }

    .card-footer {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    &:hover .team-avtar:after {
      opacity: 0.6;
    }
  }

  .card>.card-body>.card-title {
    font-family: "Montserrat", "PT Sans", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    color: #444444;
  }

  .card>.card-footer {
    background-color: #fff;
    border-top: none;
    padding: 0px 20px 20px;
  }

  .card-footer-link,
  p {
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
  }

  .card-footer-link {
    font-size: 16px;
    color: #8351FF;
    -webkit-filter: none !important;
    filter: none !important;
  }

  p {
    letter-spacing: 0em;
  }

  .card>.card-body {
    padding: 5 20px;
  }
}




/*--------------------------------------------------------------
# Testimonials Section
--------------------------------------------------------------*/

.testimonials .testimonial-wrap {
  padding-left: 50px;
}

.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
  overflow: hidden;
}

.testimonials .testimonial-item {
  box-sizing: content-box;
  padding: 30px 30px 30px 60px;
  margin: 0 15px 30px 0;
  min-height: 200px;
  box-shadow: 0px 2px 20px rgba(82, 86, 94, 0.1);
  position: relative;
  background: #fff;
}


.testimonials .swiper-pagination {
  // margin-top: 50px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #d5d7da;
  opacity: 1;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #976DFF;
}

@media (max-width: 767px) {
  .testimonials .testimonial-wrap {
    padding-left: 0;
  }

  .testimonials .testimonials-carousel,
  .testimonials .testimonials-slider {
    overflow: hidden;
  }

  .testimonials .testimonial-item {
    padding: 30px;
    margin: 15px;
  }

  .testimonials .testimonial-item .testimonial-img {
    position: static;
    left: auto;
  }
}

.testimonials .swiper-pag-container,
.swiper_thumbnail .swiper-pag-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 50px;
  padding-left: 30px;


  @media (max-width: 992px) {
    padding-top: 25px;
  }
}


.testimonials .container {
  max-width: 1200px;

  .swiper-slide {
    height: auto;
  }

}

.swiper-pagination-progressbar.swiper-pagination-horizontal {
  height: 3px;
  width: 90%;

  .swiper-pagination-progressbar-fill {
    color: #D971B0;
  }
}


.testimonials .swiper-button-next,
.testimonials .swiper-button-prev {
  position: relative;
  margin-top: 0;
  margin: 15px;
  left: none;
  right: none;
}


.testimonials .swiper {
  padding-top: 15px;
  padding-bottom: 15px;

  .swiper-slide {
    text-align: left;
  }
}




h3.heading {
  font-family: "Montserrat", "PT Sans", sans-serif;
  font-size: 39px;
  font-weight: 700;
  line-height: 41px;
  letter-spacing: 0em;
  text-align: left;
  color: #2E2C2C;
  margin: 0 0 20px;

  @media (max-width: 1200px) {
    text-align: center;
    font-size: 24px;
    line-height: 24px;
  }

  &--center {
    text-align: center;
  }
}

p.heading {
  font-family: PT Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  color: #000000;
  text-align: left;

  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 21px;
    text-align: center;
  }

  &--center {
    text-align: center;
  }

}


.slides-2 {
  .card-text {
    line-height: 20px;
  }
}

.slides-2,
.slides-4 {
  padding-right: 10px;
  padding-left: 10px;
}


.swiper-grid>.swiper-wrapper {
  flex-wrap: wrap;
}

.swiper-grid-column>.swiper-wrapper {
  flex-wrap: wrap;
  flex-direction: column;
}

.swiper .theme-hover-02:hover {
  background: #FDCD56;
  border-color: #FDCD56;
}

.testimonials .swiper {
  padding-top: 15px;
  padding-bottom: 15px;

  .swiper-slide {
    text-align: left;
  }
}


.testimonials .container {
  max-width: 1200px;

  .swiper-slide {
    height: auto;
  }

}

.slides-2 {
  .card-text {
    line-height: 20px;
  }
}
.video-player-container {
    position: relative;

    img {
        width: 100%;
    }
}

.video-player-button {
    position: absolute;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    box-shadow: 0px 0px 5px 0px rgba(43, 43, 43, 0.2);
    background-color: #FFFFFF;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    z-index: 1;
    cursor: pointer;
    background-image: url("../img/video-gallery/play-button.svg");
    background-repeat: no-repeat;
    background-size: 32% auto;
    background-position: 55% 50%;
    opacity: 0.85;

    &--small {
        background-size: 24% auto;
        width: 50px;
        height: 50px;
    }

    &:hover {
        opacity: 1;
    }
}

.video-player {
    width: 100%;

    &--main {
        box-shadow: 0px 0px 20px 4px rgba(43, 43, 43, 0.14);
    }
}

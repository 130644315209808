/*-----------------------------------------
*  Pricing Page Form
-----------------------------------------*/

.formPricing {
    background-image: url("../img/front/bg-footer-front-page.webp");
    background-repeat: no-repeat;
    background-size: auto;
    background-position: top;
    margin-top: -225px;

    .container {
        height: 100%;
        max-width: 1170px;
        padding: 165px 0 0 0;

        @media (max-width: 1280px) {
            max-width: 90%;
        }
    }

    .pricing-request-button {
        width: 225px;
        cursor: pointer;
    }
}

.request-demo-form {
    display: flex;
    flex-direction: column;
    padding: 50px;
    margin: 120px 0 80px;
    background-color: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(117, 117, 117, 0.11);
    border-radius: 13px;

    @media (max-width: 769px) {
        padding: 30px;
    }
}

.request-demo-form-title {
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 900;
    line-height: 29px;
    color: #444444;

    @media (max-width: 769px) {
        font-size: 20px;
        line-height: 24px;
    }
}

.request-demo-form-info {
    padding: 30px 0 45px 0;
    font-family: 'Pt Sans';
    font-style: normal;
    font-weight: 400;
    color: #55595C;
    font-size: 16px;
    line-height: 28px;

    @media (max-width: 769px) {
        padding: 10px 0 15px;
        font-size: 14px;
        line-height: 20px;
    }
}

.all-form-sections {
    display: flex;
    flex-direction: row;
    justify-content: space-between;


    @media (max-width: 992px) {
        flex-direction: column;
    }

    .form-section {
        width: 48%;

        @media (max-width: 992px) {
            width: 100%;
        }
    }
}

.demo-form-group {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    height: 44px;

    @media (max-width: 769px) {
        margin-bottom: 20px;
        height: 36px;
    }

    label.error {
        position: absolute;
        font-size: 11px;
        color: red;
        left: 5px;
        top: 35px;
        background-color: #fff;
        border-radius: 3px;
        padding: 0 5px;

        @media (max-width: 769px) {
            top: 28px;
            font-size: 9px;
        }
    }

    .demo-form-group-info {
        font-size: 11px;
        position: absolute;
        bottom: -2px;
        right: 3px;
        letter-spacing: 0;
        transition: 0.2s opacity;
    
        @media (max-width: 769px) {
            font-size: 9px;
        }

        b {
            color: red;
        }
    }
    
    .demo-form-control {
        outline: none;
        width: 100%;
        height: 100%;
        border: 1px solid rgba(111, 111, 111, 0.3);
        border-radius: 3px;
        font-family: Pt Sans;
        font-weight: 400;
        line-height: 17px;
        font-size: 14px;
        color: #444444;
        padding: 0 30px;

        &::placeholder {
            opacity: 0;
        }

        &:not(:placeholder-shown), &:focus {
            &::placeholder {
                opacity: 0.5;
                right: 0;
            }

            ~ .demo-form-group-info {
                opacity: .2;
            }

            ~ .demo-input-label {
                margin-left: 10px;
                top: 0;
                background-color: #fff;
                border-radius: 3px;
                border-color: #000;
                border: 1px;
                padding: 0 5px;
                transform: translate(-6px, -14px) scale(0.8);
            }
        }
        
        &.error-demo {
            border-color: #ffa3a3;
        }

        label.error {
            position: absolute;
            font-size: 18px;
            color: red;
            top: calc(100% - 12px);
            left: 5px;
            letter-spacing: -0.3px;
        }
    
        input.error-demo {
            ~ .form-group-info {
                bottom: 8px;
            }
        }
    
    }

    .demo-input-label {
        position: absolute;
        left: 0;
        top: 10px;
        padding: 0 30px;
        transition: 0.2s transform;
        transform-origin: 0 50%;

        @media (max-width: 769px) {
            top: 6px;
        }
    }
}

.demo-form-group-text {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    height: 236px;

    @media (max-width: 769px) {
        height: 150px;
    }

    label.error {
        position: absolute;
        font-size: 11px;
        color: red;
        left: 5px;
        top: 225px;
        background-color: #fff;
        border-radius: 3px;
        border-color: #000;
        border: 1px;
        padding: 0 5px;

        @media (max-width: 769px) {
            top: 142px;
            font-size: 9px;
        }
    }

    .demo-form-control {
        outline: none;
        width: 100%;
        height: 100%;
        border: 1px solid rgba(111, 111, 111, 0.3);
        border-radius: 3px;
        font-family: Pt Sans;
        font-weight: 400;
        line-height: 17px;
        font-size: 14px;
        color: #444444;
        padding: 20px 30px;
        resize: none;

        &::placeholder {
            opacity: 0;
        }

        &:not(:placeholder-shown), &:focus {
            &::placeholder {
                opacity: 0.5;
                right: 0;
            }

            ~ .demo-form-group-info {
                opacity: .2;
            }

            ~ .demo-input-label {
                margin-left: 10px;
                top: 0;
                background-color: #fff;
                border-radius: 3px;
                border-color: #000;
                border: 1px;
                padding: 0 5px;
                transform: translate(-6px, -14px) scale(0.8);
            }
        }
        
        &.error-demo {
            border-color: #ffa3a3;
        }

        label.error {
            position: absolute;
            font-size: 11px;
            color: red;
            top: calc(100% - 12px);
            left: 5px;
            letter-spacing: -0.3px;
        }
    
        input.error-demo {
            ~ .form-group-info {
                bottom: 8px;
            }
        }
    }

    .demo-input-label {
        position: absolute;
        left: 0;
        top: 10px;
        padding: 0 30px;
        transition: 0.2s transform;
        transform-origin: 0 50%;
    }
}

.demo-confirm-form {
    position: relative;
    margin: 15px 0 15px 10px;
    flex-direction: row;
    display: flex;

    @media (max-width: 769px) {
        // margin: 20px 0 30px 10px;
    }

    label.error {
        position: absolute;
        top: -20px;
        left: 0;
        font-size: 11px;
        color: red;

        @media (max-width: 769px) {
            top: -20px;
            font-size: 9px;
        }
    }

    .checkbox {
        position: absolute;
        cursor: pointer;
        top:-2px;
        left: -10px;
        width:30px; 
        height:30px;
        border: 1px solid rgba(111, 111, 111, 0.3);
        border-radius: 3px;
        background-color: #FFFFFF;
        transition: .3s background-color;
    }

    .checkbox:after {
        content: "";
        position: absolute;
        display: none;
        left: 6px;
        top: 1px;
        width: 16px;
        height: 21px;
        border: solid white;
        border-width: 0 6px 6px 0;
        transform: rotate(35deg);
    }

    .checkbox:hover span {
        background-color: #ccc;
    }

    .demo-checkbox:checked ~ .checkbox{
        background-color: #3064FF;
    }

    .demo-checkbox:checked ~ .checkbox:after {
        display:block;
    }

    .demo-checkbox-label {
        padding-left: 30px;

        @media (max-width: 769px) {
            padding-left: 20px;
        }
    }
}

.private-police {
    background: rgba(255, 0, 0, 0.1);
    font-size: 12px;
    color: black;
    margin: 15px 0;
    border-radius: 3px;
    padding: 10px;
}

.selected-checkbox {
    background: rgba(0, 255, 60, 0.1);
}

.output_message {
    vertical-align: top;
    margin-top: 15px;
    padding: 4px 10px;
    float: left;
    width: 100%;
    font-size: 13px;
    text-align: center;
    letter-spacing: .5px;
    border-radius: 3px;

    &.success {
        background: #0073f4;
        display: block;
        color: #ffffff;
        padding: 10px;
    }

    &.error {
        background: #dc1e1e;
        display: block;
        color: #ffffff;
    }
}

.add-file-input {
    position: relative;
    display: block;
    width: 100%;
    margin-bottom: 20px;
    height: 44px;
    border-radius: 3px;
    border: 1px solid rgba(111, 111, 111, 0.3);


    input {
        visibility: hidden;
        opacity: 0;
        width: 100%;
        height: 44px;
        z-index: 2;

    }

    label {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        cursor: pointer;
        line-height: 44px;
        padding-left: 30px;
        width: 100%;
        height: 100%;
    }

    label.error {
        position: absolute;
        display: block;
        font-size: 11px;
        color: red;
        top: 15px;
        left: calc(100% -20px);
        letter-spacing: -0.3px;
        height: 5px;
    }

}



.add-file-form label {
    height: 40px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #FAFAFA;

    span {
        margin-right: 1rem;
        width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    i {
        margin-right: 1rem
    }
}

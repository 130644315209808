/*-----------------------------------------
*  GALLERY Component
-----------------------------------------*/

.grayBg {
    background-color: #F8F8F8;
}

.grayBg-2 {
    background-color: #FCFCFC;
}

.mainVideoGallery {
    padding-top: 180px;
    position: relative;
    z-index: 2;


    &--grayBg {
        background-color:#F8F8F8;
    }
    @media (max-width: 992px) {
        background-size: 100% auto;
        background-attachment: local;
        padding-top: 100px;
    }

    .container {
        @media (max-width: 767px) {
            max-width: 100%;
        }
    }
}

.main-post {
    padding-bottom: 50px;

    .container {
        max-width: 1200px !important;
    }

    @media (max-width: 992px) {
        padding-bottom: 30px;
    }
}

.main-video .container {
    max-width: 1200px !important;
}

.video-head-title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 900;
    font-size: 42px;
    line-height: 50px;
    color: #444;
    margin-top: 30px;
    margin-bottom: 30px;

    @media (max-width: 767px) {
        font-size: 32px;
        margin-bottom: 15px;
        text-align: center;
        line-height: 35px;
    }

    &--page {
        margin: 0;
        font-size: 32px;
    }
}

.video-head-link div{
    display: flex;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    padding-top: 30px;
    padding-bottom: 30px;

    @media (max-width: 992px) {
        flex-wrap: wrap;
        justify-content: center;
        padding-top: 15px;
        padding-bottom: 15px;
    }

     a {
        color: #0073F4;
        text-transform: uppercase;
        margin-right: 12px;
        margin-left: 12px;

        &:hover {
            text-decoration: underline !important;
        }
     }
}

.media-block {
    margin-top: 80px;
    margin-bottom: 50px;
    width: 100%;
    background-color: #FFFFFF;
    border-radius: 0 0 9px 9px;
    box-shadow: 0px 4px 11px rgba(62, 62, 62, 0.14);

    @media (max-width: 992px) {
        margin-top: 40px;
        margin-bottom: 30px;
    }

    &--page {
        margin-top: 40px;
    }
}

.photo-container {
    position: relative;

    img {
        width: 100%;
    }
}

.text-container {
    padding: 30px;
}

.iframe_video {
    position: relative;
    padding-bottom: 56.25%; /* задаёт высоту контейнера для 16:9 (если 4:3 — поставьте 75%) */
    height: 0;
    overflow: hidden;
}

.iframe_video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-width: 0;
    outline-width: 0;
}

.video-content {
    font-family: 'Pt Sans';
    font-style: normal;
    line-height: 145%;
    color: #444444;
    padding: 50px;
    
    @media (max-width: 992px) {
        padding: 20px;
    }
}

.video-content-header {
    font-weight: 600;
    font-size: 28px;
    margin-bottom: 30px;
}

.video-content-text {
    font-weight: 400;
    font-size: 14px;
    text-align: justify;
    line-height: 20px;

    @media (max-width: 992px) {
        line-height: 17px;
    }
}

.innerPageTopBg {
    position: absolute;
    left: -370px;
    top: 0;
    width: 140%;
    height: auto;
    z-index: -1;

    img {
        width: 100%;
        height: auto;
    }

    @media (max-width: 992px) {
        left: -200px;
        width: 180%;
    }

    @media (max-width: 767px) {
        display: none;
    }
}

.mediaGallery-filtration,
.vacancyGalary {
    padding-top: 15px;

    .container {
        max-width: 1200px !important;
    }
}

.mainVideoGallery-wrapper {
    max-width: 850px;

    @media (max-width: 1200px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 100%;
    }

    &--vacancy {
        max-width: 600px;

        @media (max-width: 992px) {
            max-width: 100%;
        }
    }
}

.mediaGallery-filtration .background-vacancy {
    background-image: url("../img/background/bg-gallery.svg");
    background-repeat: no-repeat;
    background-size: auto;
    background-position: right top;
    // background-size: 400px;

    @media (max-width: 992px) {
        background-image: none;
    }
}

.mediaGallery-filtration {
    position: relative;
    z-index: 2;
}

.mainVideoGallery-head-title {
    font-family: Montserrat;
    font-size: 56px;
    font-style: normal;
    font-weight: 900;
    line-height: 50px;
    color: #444444;
    max-width: 600px;

    &--post {
        max-width: 700px;
    }

    &--intresting {
        font-size: 42px;
        max-width: 700px;
        padding-bottom: 40px;

        @media (max-width: 992px) {
            padding-bottom: 20px;
            max-width: 100%;
            text-align: center;
        }
    }

    @media (max-width: 992px) {
        font-size: 28px;
        line-height: 30px;
    }
}

.mainVideoGallery-head-slogan {
    padding-top: 30px;
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
}

.mainVideoGallery-head-info {
    padding-top: 30px;
    padding-bottom: 30px;
    font-style: normal;
    font-family: 'Pt Sans';
    font-weight: 400;
    color: #444444;
    font-size: 16px;
    line-height: 28px;

    @media (max-width: 992px) {
        font-size: 17px;
        width: auto;
        padding-top: 20px;
        padding-bottom: 20px;
        line-height: 25px;
    }

    @media (max-width: 767px) {
        padding-top: 10px;
        padding-bottom: 20px;
        font-size: 14px;
        line-height: 20px;
    }
}

.buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0 15px;
    color: #444444;

    @media (max-width: 1200px) {
        justify-content: center;
    }

    @media (max-width: 992px) {
        flex-wrap: wrap;
    }
}

.buttons-container-text {
    max-width: 340px;
    font-family: Pt Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;

    @media (max-width: 992px) {
        text-align: center;
    }

    @media (max-width: 767px) {
        font-size: 17px;
        line-height: 20px;
        padding-bottom: 10px;
    }
}

.buttons-container-buttons {
    display: flex;
    flex-wrap: wrap;

    a {
        margin: 5px;
    }

    @media (max-width: 992px) {
        justify-content: center;
    }
}

.botton-filtration {
    position: relative;
    border: 0px;
    border-radius: 6px;
    width: auto;
    height: 44px;
    justify-content: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: darken(#ffffff, 70%);
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    padding: 14px 20px;
    text-transform: uppercase;
    z-index: 1;
    overflow: hidden;


    @media (max-width: 992px) {
        height: 30px;
        padding: 10px 15px;
    }

    &:hover,
    &.active {
        color: #ffffff;
        box-shadow: 0px 20px 24px 0px rgba(0, 11, 40, 0.1);

        &::after {
            opacity: 0;
        }
    }

    &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        background: #fff;
        opacity: 0.8;
        z-index: -1;
        border-radius: inherit;
    }

    &--red {
        background: #D971B0;
    }

    &--orange {
        background: #FDCD56;
    }

    &--rose {
        background: #8068BC;
    }

    &--blue {
        background: #0073F4;
    }

    &--green {
        background: #1DBC00;
    }
}

.vacancyGallery-search {
    padding-bottom: 30px;
    padding-top: 45px;

    @media (max-width: 992px) {
        padding-bottom: 10px;
        padding-top: 30px;
    }
}




.vacancyGallery {
    .container {
        display: flex;
        flex-direction: column;

        @media (max-width: 1200px) {
            padding-top: 50px;
            padding-bottom: 20px;
        }

        @media (max-width: 667px) {
            padding-top: 30px;
            padding-bottom: 0px;
        }
    }
}


.vacancyGallery,
.vacancyGallery-search {

    .container {
        max-width: 1200px !important;
    }
}

.blogPricing-container-post {
    font-family: 'Pt Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    display: flex;
    background-color: #fff;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(117, 117, 117, 0.11);
    border-radius: 16px;
    margin-bottom: 50px;
    position: relative;
    float: left;
    page-break-inside: avoid;
    break-inside: avoid;

    @media (max-width: 1200px) {
        margin-bottom: 35px;
    }

    @media (max-width: 992px) {
        margin-bottom: 25px;
    }

    &--purple {
        .blogPricing-container-post-text-colored-rectangle {
            background: #AC8BFF;
        }

        .blogPricing-container-post-text-header,
        h4 {
            color: #AC8BFF;
        }
    }

    &--purple-hard {
        .blogPricing-container-post-text-colored-rectangle {
            background: #8458FF;
        }

        .blogPricing-container-post-text-header,
        h4 {
            color: #8458FF;
        }
    }

    &--green {
        .blogPricing-container-post-text-colored-rectangle {
            background: #1DBC00;
        }

        h4 {
            color: #1DBC00;
        }
    }

    &--orange {
        .blogPricing-container-post-text-colored-rectangle {
            background: #FDCD56;
        }

        h4 {
            color: #FDCD56;
        }
    }

    &--rose {
        .blogPricing-container-post-text-colored-rectangle {
            background: #D971B0;
        }

        h4 {
            color: #D971B0;
        }
    }
}

.blogPricing-container-post-text-colored-rectangle {
    position: absolute;
    width: 10px;
    height: 82px;
    left: 0px;
    top: 45px;
    border-radius: 2px;

    @media (max-width: 992px) {
        top: 20px;
    }

    @media (max-width: 767px) {
        width: 7px;
    }
}

.blogPricing-container-post-text {
    padding: 50px 50px;
    font-size: 16px;
    color: #6A6A6A;

    @media (max-width: 1200px) {
        padding: 35px 35px;
    }

    @media (max-width: 992px) {
        padding: 25px 10px 25px 25px;
    }

    @media (max-width: 520px) {
        padding: 15px 10px 15px 25px;
    }

    h3 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        color: #444444;

        @media (max-width: 767px) {
            font-size: 20px;
        }
    }

    h4 {
        font-family: Pt Sans;
        font-weight: 600;
        font-size: 18px;

        @media (max-width: 520px) {
            padding-top: 0px;
        }
    }

    a {
        font-family: Pt Sans;
        color: #2A8FEC;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        text-decoration: underline;
    }

    span {
        display: inline-block;
        padding: 15px 0 15px 0;
        font-family: Pt Sans;
        color: #6A6A6A;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;

        @media (max-width: 1200px) {
            padding: 10px 0;
        }

        @media (max-width: 992px) {
            padding: 7px 0;
        }
    }

    .blogPricing-container-post-text-read-more {
        font-family: Pt Sans;
        font-size: 18px;
        font-weight: 700;
        line-height: 22px;

        &:hover {
            border-bottom: 1px solid #0073F4;
        }
    }
}

.blogPricing-container-post-text-separation {
    display: flex;
    flex-direction: row;

    @media (max-width: 767px) {
        flex-direction: column;
    }
}

.blogPricing-container-post-text-separation-block {
    width: 50%;
    padding-right: 15px;
    font-family: Pt Sans;

    @media (max-width: 767px) {
        width: 100%;
        padding-left: 20px;
    }

    h3 {
        text-transform: uppercase;
        margin-top: 30px;
        color: #2A8FEC;
        font-size: 16px;
        font-weight: 600;
        line-height: 19px;

        @media (max-width: 767px) {
            margin-top: 15px;
        }
    }

    span {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        padding-top: 10px;
    }
}

.videoGallery-count-pagination {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    // margin-top: -50px;
    margin-bottom: 80px;

    @media (max-width: 992px) {
        margin-bottom: 10px;
        margin-top: 20px;
    }

    @media (max-width: 767px) {
        margin-top: 10px;
    }
}

.button-ellipse {
    background: #FFFFFF;
    box-shadow: 0px 4px 11px rgba(62, 62, 62, 0.14);
    width: 70px;
    height: 70px;
    border-radius: 50%;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;


    @media (max-width: 992px) {
        width: 40px;
        height: 40px;
    }

    .button-ellipse-arrow {
        width: 0;
        height: 0;
        border-top: 15px solid transparent;
        border-bottom: 15px solid transparent;
        position: absolute;

        @media (max-width: 992px) {
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
        }

        &--left {
            border-left: 20px solid #C4C4C4;
            margin-left: 3px;

            @media (max-width: 992px) {
                border-left: 10px solid #C4C4C4;
            }
        }

        &--right {
            border-right: 20px solid #C4C4C4;
            margin-left: -3px;


            @media (max-width: 992px) {
                border-right: 10px solid #C4C4C4;
                margin-left: -3px;
            }
        }
    }
}

.videoGallery-count-pagination a:hover {
    .button-ellipse-arrow--right {
        border-right: 20px solid #1DBC00;

        @media (max-width: 992px) {
            border-right: 10px solid #1DBC00;
        }
    }

    .button-ellipse-arrow--left {
        border-left: 20px solid #1DBC00;

        @media (max-width: 992px) {
            border-left: 10px solid #1DBC00;
        }
    }
}

.videoGallery-lists {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-left: 35px;
    margin-right: 35px;

    @media (max-width: 992px) {
        flex-wrap: wrap;
    }

    a {
        margin: 5px 15px;

        @media (max-width: 992px) {
            margin: 5px 10px;
        }

        @media (max-width: 520px) {
            margin: 5px 7px;
        }
    }

    .videoGallery-list {
        width: 70px;
        height: 7px;
        background-color: #C4C4C4;
        border-radius: 9px;
        margin-top: 10px;
        margin-bottom: 10px;
        align-items: center;

        @media (max-width: 992px) {
            width: 20px;
            height: 20px;
        }
    }

    .videoGallery-list:hover {
        background-color: #1DBC00;
    }
}

.videoGallery-footer {
    position: relative;
    z-index: 1;

    .container {
        max-width: 1200px !important;
    }
}

.videoGallery-footer-bg {
    position: absolute;
    width: 140%;
    z-index: -1;
    left: -500px;
    bottom: 0;
    display: flex;
    justify-content: center;

    img {
        width: 100%;
        height: auto;
    }


    @media (max-width: 992px) {
        left: -100px;
        width: 200%;
    }

    @media (max-width: 767px) {
        display: none;
    }
}


/*-----------------------------------------
*   PAGE Component
-----------------------------------------*/


.main-vacancy {
    padding-bottom: 40px;

    .container {
        max-width: 1200px !important;
    }

    @media (max-width: 992px) {
        padding-bottom: 40px;
    }
}


.video-head {
    width: 100%;
}

.video-head-date {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 300;
    font-size: 28px;
    line-height: 34px;
    color: #999;
    margin-bottom: 30px;
    
    @media (max-width: 767px) {
        margin-bottom: 20px;
        text-align: center;
    }
}

.video-head-selection-vacancy {

    a {
    color: #15DFA0;
    text-transform: uppercase;
    margin-right: 40px;

    @media (max-width: 767px) {
        display: block;
        margin-right: 0px;
    }
    }
}

.vocation-head-title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 900;
    font-size: 42px;
    line-height: 50px;
    color: #444;
    margin-bottom: 30px;

    @media (max-width: 767px) {
        margin-bottom: 15px;
        font-size: 32px;
        text-align: center;
        line-height: 35px;
    }
}

.video-head-link div{
    display: flex;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    padding-top: 30px;
    padding-bottom: 30px;

    @media (max-width: 992px) {
        flex-wrap: wrap;
        justify-content: center;
        padding-top: 15px;
        padding-bottom: 15px;
    }

     a {
        color: #0073F4;
        text-transform: uppercase;
        margin-right: 12px;
        margin-left: 12px;

        &:hover {
            text-decoration: underline !important;
        }
     }
}

.post-content {
    margin-top: 30px;
    margin-bottom: 40px;

    a > img {
        padding-bottom: 25px !important;
    }
}

.vacancy-content {
    font-family: 'Pt Sans';
    font-style: normal;
    line-height: 28px;
    color: #444444;

    .post-content-text {
        font-weight: 400;
        font-size: 16px;
        color: #55595C;
        line-height: 28px;
        margin-top: 30px;

        img {
            margin-top: 80px;
            border-bottom: 2px solid #99cfff;
            }

        h3 {
            font-size: 16px;
            font-weight: 600;
            line-height: 19px;
            color: #2A8FEC;
            margin-bottom: 20px;
            margin-top: 50px;
            text-transform: uppercase ;

            @media (max-width: 992px) {
                margin-top: 15px;
            }
        }
    }
}

ul.rounded-marker {
    padding-left: 0px;

    li {
        list-style-type: none;
        background-repeat: no-repeat;
        padding-left: 20px;
        padding-top: 15px;
    }

    &--blue {
        li {
            background: url(../img/dot-blue.svg) no-repeat left top 22px;
        }
    }

    &--green {
        li {
            background: url(../img/dot-green.svg) no-repeat left top 23px;
            padding-left: 30px;
        }
    }
}

.rounded {
    counter-reset: li;
    list-style: none;
    padding: 0;
}

.rounded li {
    position: relative;
    display: block;
    padding: 0;
    margin-left: 45px;
}

.rounded li:before {
    counter-increment: li;
    content: "";
    position: absolute;
    left: -3em;
    top: 50%;
    margin-top: -1.3em;
    background: #15DFA0;
    height: 1em;
    width: 1em;
    line-height: 2em;
    border: .1em solid white;
    border-radius: 2em;
}

.vacancy-button .pricing-request-button {
    margin-top: 30px;
    margin-bottom: 80px;

    @media (max-width: 992px) {
        margin-top: 30px;
        margin-bottom: 40px;
    }
}


.mainVideoGallery-footer {
    padding-top: 180px;
    padding-bottom: 50px;
    background-color: #F8F8F8;

    @media (max-width: 992px) {
        background-size: 100% auto;
        background-attachment: local;
    }

    .container {
        @media (max-width: 767px) {
            max-width: 100%;
        }
    }
}


.formVacancy {
    background-image: url("../img/background/bg-footer-white-border.svg");
    background-repeat: no-repeat;
    background-size: auto;
    background-position: bottom;
    margin-top: -225px;

    .container {
        height: 100%;
        max-width: 1170px;
        padding: 165px 0 0 0;

        @media (max-width: 1280px) {
            max-width: 90%;
        }
    }

    .pricing-request-button {
        width: 155px;
        cursor: pointer;
    }
}


.mediaGallery-post-container {
    max-width: 1200px;
    padding: 15px;

    @media (max-width: 992px) {
        padding-bottom: 40px;
    }

    @media (max-width: 767px) {
        padding-bottom: 10px;
    }
}

.postGallery-cards {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    flex-wrap: wrap;
    align-items: stretch;
    align-content: center;
    font-family: Pt Sans;
    font-weight: 600;
    padding-top: 50px;

    @media (max-width: 1200px) {
    justify-content: center;
    padding-top: 20px;
    }
}

.mediaGallery-cards {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: stretch;
    align-content: center;
    font-family: Pt Sans;
    font-weight: 600;
    padding-top: 50px;

    @media (max-width: 1200px) {
        justify-content: center;
        padding-top: 20px;
    }
}


.mediaGallery-cards-header {
    font-family: Montserrat;
    font-size: 42px;
    font-weight: 900;
    line-height: 50px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 40px;

    @media (max-width: 992px) {
        padding-top: 20px;
        font-size: 25px;
        justify-content: center;

    }

    @media (max-width: 767px) {
        padding-top: 10px;
        font-size: 20px;
        line-height: 20px;
        justify-content: center;
    }

    .mediaGallery-cards-count {
        font-family: Montserrat;
        font-size: 28px;
        font-weight: 300;
        line-height: 34px;
        text-align: right;
        
        @media (max-width: 992px) {
            font-size: 20px;
            line-height: 20px;
        }
    }
}


.mediaGallery-cards-post {
    background-color: #fff;
    border-radius: 37px 37px 37px 37px;
    box-shadow: 0px 4px 11px rgba(62, 62, 62, 0.14);
    // flex-grow: 1;
    // flex-shrink: 0;
    flex-basis: 560px;
    margin: 1% 1% 40px 1%;

    @media (max-width: 1200px) {
        flex-basis: 800px;
        margin: 1% 1% 20px 1%;
    }
}

.post-container {
    overflow: hidden;
    border-radius: 37px 37px 0 0;
    max-height: auto;

    &--not-radius {
        border-radius: 0;
        max-height: 250px;

        @media (max-width: 600px) {
            max-height: auto;
        }
    }

    &--in-carousel {
        border-radius: 0;
        max-height: 210px;

        @media (max-width: 600px) {
            max-height: auto;
        }
    }

    div img {
        background-size: cover;
        background-position: center center;
        width: 100%;
        height: 100%;
    }
}


.mediaGallery-cards-publication {
    display: flex;
    justify-content: space-between;
    width: 100%;
    color: #444444;
    padding-bottom: 80px;
}

.mediaGallery-cards-publication-card {
    position: relative;
    height: 470px;
    width: 97,5%;
    margin: 10px 10px;
    background: #FFFFFF;
    box-shadow: 0px 4px 11px rgba(62, 62, 62, 0.14);
    overflow: hidden;
    border-radius: 0 0 9px 9px;

    &--small {
        height: 400px;
    }

    
    &--big {
        height: auto;
    }
}

.mediaGallery-cards-card {
    background-color: #fff;
    border-radius: 0 0 9px 9px;
    box-shadow: 0px 4px 11px rgba(62, 62, 62, 0.14);
    // flex-grow: 1;
    // flex-shrink: 0;
    flex-basis: 365px;
    margin: 1% 1% 40px 1%;

    @media (max-width: 1200px) {
        flex-basis: 400px;
    }

    @media (max-width: 992px) {
        flex-basis: 600px;
    }
}

.mediaGallery-cards-card-info {
    padding: 30px;
    padding-right: 15px;

    &--publication {
    // padding: 0 15px 30px 30px;

        @media (max-width: 992px) {
            // padding: 5px;
        }
    }

    @media (max-width: 992px) {
        padding: 10px 10px 20px 20px;
    }

    h3 {
        font-weight: 600;
        font-size: 20px;
        line-height: 29px;

        @media (max-width: 766px) {
            line-height: 22px;
        }
    }

    span {
        font-family: Pt Sans;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px !important;

        @media (max-width: 766px) {
            line-height: 18px;
        }
    }

    p {
        font-family: Pt Sans;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px !important;
        // padding-top: 20px;
        // padding-bottom: 10px;
        @media (max-width: 766px) {
            line-height: 18px;
        }
    }

    a {
        display: block;
        // padding-top: 20px;
        color: #0073F4;
        font-size: 18px;

        &:hover {
            text-decoration: underline !important;
        }

        @media (max-width: 992px) {
            // padding-top: 10px;
        }
    }
}

.mediaGallery-cards-post-info {
    padding: 30px;

    @media (max-width: 1200px) {
        padding: 10px 10px 20px 20px;
    }

    h3 {
        font-family: Montserrat;
        font-weight: 900;
        font-size: 25px;
        line-height: 15px;
        padding-bottom: 10px;

        @media (max-width: 767px) {
            font-size: 25px;
            line-height: 25px;
        }
    }

    span {
        font-family: Pt Sans;
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
    }

    a {
        font-family: Pt Sans;
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        display: block;
        color: #0073F4;

        &:hover {
            text-decoration: underline !important;
        }

        @media (max-width: 992px) {
            padding-top: 10px;
        }
    }

    .mediaGallery-cards-post-info-detail-link {
        padding-top: 20px;

        &:hover {
            text-decoration: underline !important;
        }
    }
}

.post-tags {
    display: flex;
    flex-wrap: wrap;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    padding: 8px 10px 8px 10px;

    &--bg {
        background-color: #F4F4F4;
    }

    @media (max-width: 1200px) {
        justify-content: center;
        padding: 10px;
        font-size: 13px;
        line-height: 15px;
    }

     a {
        margin-right: 12px;
        margin-left: 12px;
        color: #0073F4;
        text-transform: uppercase;

        &--colored {
            margin-right: 10px;
        }
    
        &:hover {
            text-decoration: underline !important;
        }
        
        @media (max-width: 992px) {
            margin-right: 5px;
            margin-left: 5px;
        }
    }
}


/*-----------------------------------------
*   Search
-----------------------------------------*/

.vacancyGallery, .vacancyGallery-search {

    .container {
        max-width: 1200px !important;
    }
}

.vacancyGallery-search {
    padding-bottom: 30px;
    padding-top: 45px;

    @media (max-width: 992px) {
        padding-bottom: 10px;
        padding-top: 30px;
    }
}

.vacancy-search {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 16px;
    box-shadow: 0px 4px 10px rgb(117 117 117 / 11%);
    background: #ffffff;
    padding: 20px;

    .vacancy-form-header {
        display: block;
        font-family: Montserrat;
        font-size: 24px;
        font-weight: 900;
        line-height: 29px;
        flex-grow: 1;
        margin: 0 30px;

        @media (max-width: 992px) {
            text-align: center;
        }
    }
}

.vacancy-search-result {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    border-radius: 16px;
    box-shadow: 0px 4px 10px rgb(117 117 117 / 11%);
    background: #ffffff;
    padding: 60px 50px 50px;
    width: 100%;

    .vacancy-form-header {
        display: block;
        font-family: Montserrat;
        font-size: 24px;
        font-weight: 900;
        line-height: 29px;
        flex-grow: 1;
        margin-bottom: 20px;
    }
    span {
        font-family: Pt Sans;
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;
    }
}

#checkVacancy {
    zoom: 2;
    opacity:0.9;
    cursor:pointer;
}

.searchform {
    position: relative;
    max-width: auto;
    margin: 20px 40px;
    flex-grow: 3;

    input,
    button {
        outline: none;
        background: transparent;
    }

    input {
        width: 100%;
        height: 44px;
        padding-left: 30px;
        padding-right: 40px;
        border: 1px solid #504e4e;
        border-radius: 3px;
    }

    button {
        border: none;
        height: 44px;
        width: 44px;
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        color: #0073F4;
        font-size: 20px;
    }

    input:focus {
        border-color: #0073F4;
    }
}

/* ajax search */

.search-form {
    position: relative;
}

.ajax-search {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    right: 0;
    background: #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    margin-top: 5px;
    max-height: 230px;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 100;
    margin: 0;
    padding: 0;
    font-size: 14px;
    color: #424348;
    scrollbar-color: dark;
    scrollbar-width: thin;
    overscroll-behavior: contain;
}

.ajax-search::-webkit-scrollbar {
    width: 6px;
    background-color: #eff2f3;
}

.ajax-search::-webkit-scrollbar-thumb {
    background-color: #dddddd;
    border-radius: 4em;
}

.ajax-search__item {
    position: relative;
    border-top: 1px solid rgba(224, 229, 231, 0.5);
    padding: 10px 15px;
    cursor: pointer;
    list-style-type: none;
}

.ajax-search__link {
    color: var(--accent);
    line-height: 130%;
    margin-bottom: 10px;
    font-size: 13px;
    display: block;
}

.ajax-search__excerpt {
    cursor: default;
    font-size: 11px;
    line-height: 140%;
}

.ajax-search__not-found {
    font-size: 12px;
}




/*-----------------------------------------
*  Стили для карусели
-----------------------------------------*/

.mediaGallery-post {

    .owl-dots {
        position: relative;
        margin: 0 auto;
        width: 77%;
        display: flex;
        justify-content:space-around;
        padding: 0 60px;

        @media (max-width: 767px) {
            padding: 30px 0 0;
        }

        .owl-dot {
            display: block;
            width: 8%;
            height: 7px;
            background: #C4C4C4;
            border-radius: 9px;

            span {
                display: none;
            }

            &:hover,
            &.active {
                background: #0073F4 !important;
            }
        }
    }

    .owl-stage-outer {
        z-index: 2;
    }

    .owl-nav {
        position: relative;
        margin: 30px 45px;
        display: flex;

        @media (max-width: 767px) {
            display: none;
        }

        .owl-prev {
            position: absolute;
            width: 70px;
            height: 70px;
            border-radius: 50%;
            cursor: pointer;
            z-index: 1;
            background-image: url("../img/front/owl-button.svg");
            background-size: 100%;
            background-position: auto;
            box-shadow: 0px 4px 11px rgba(62, 62, 62, 0.14);
            opacity: 0.93;
            left: 0;
            transform: rotate(180deg);

            i {
                display: none;
            }

            &:hover {
                opacity: 1;
                box-shadow: 0px 4px 11px rgba(62, 62, 62, 0.5);
            }
        }

        .owl-next {
            position: absolute;
            width: 70px;
            height: 70px;
            border-radius: 50%;
            cursor: pointer;
            z-index: 1;
            background-image: url("../img/front/owl-button.svg");
            background-size: 100%;
            background-position: auto;
            box-shadow: 0px 4px 11px rgba(62, 62, 62, 0.14);
            opacity: 0.93;
            right: 0;
        
            i {
                display: none;
            }

            &:hover {
                opacity: 1;
                box-shadow: 0px 4px 11px rgba(62, 62, 62, 0.5);
            }
        }
    }
}

// //////////////
// Таблица сравнения
// /////////////
section.table-comparison {
    .container {
        max-width: 1200px !important;
    }
}

.container-table {
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    outline: 0;
}

article {
    width: 100%;
    // max-width: 1200px;
    margin: 0 auto;
    // height: 1000px;
    position: relative;
}

ul {
    display: flex;
    top: 0px;
    z-index: 10;
    padding-bottom: 14px;
}

li {
    list-style: none;
    flex: 1;
    margin: 5px;

}

// li:last-child {
//     border-right: 1px solid #DDD;
// }

button {
    width: 100%;
    border: none;
    border-radius: 10px;
    padding: 10px;
    background: #FFF;
    font-size: 14px;
    font-weight: bold;
    height: 50px;
    color: #999;
    background: aliceblue;
}

li.active button {
    background: #0073f4;
    color: white;
}

table {
    border-collapse: separate;
    table-layout: fixed;
    width: 100%;
    border-spacing: 3px 0;
}

th {
    background: #ffffff;
    display: none;
}

td,
th {
    height: 53px
}

td,
th {
    border-bottom: 1px solid #DDD;
    padding: 10px;
    empty-cells: show;
}

td,
th {
    text-align: left;
}

td+td,
th+th {
    text-align: center;
    display: none;
}

td.default {
    display: table-cell;
}

h1 {
    font-size: 48px;
    text-align: center;
    margin: 30px 0;
}

.txt-l {
    font-size: 28px;
    font-weight: bold;
}

.txt-2 {
    font-size: 18px;
    margin: 10px 0;
}


.txt-top {
    position: relative;
    top: -9px;
    left: -2px;
}

.tick {
    font-size: 18px;
    color: #2CA01C;
}

.hide {
    border: 0;
    background: none;
}

// @media (min-width: 300px) {
//     td,
//     th {
//         width: 130px;
//     }
// }

@media (min-width: 767px) and (max-width: 1920px) {
    ul {
        display: none;
    }

    td,
    th {
        display: table-cell !important;
    }

    td,
    th {
        width: 230px;
    }

    td+td,
    th+th {
        width: auto;
        background-color: white
    }
}

@media (min-width: 300px) and (max-width: 766px) {
    td,
    th {
        width: 130px;
    }
}



th .wrapper, td .wrapper {
    display: flex;
    align-content: flex-start;
    justify-content: center;
    flex-direction: row;
    align-items: center;

    img {
        margin-right: 15px;
    }
    
    img.header-img {
        height: 45px;
    }
    img.cell-img {
        height: 20px;
    }

    span {
        text-align: left !important;
    }
}

tbody > tr td:first-child, tr th:first-child {
    background-color:#6D4DFF !important;
    color: white;
}

td {
    background: white !important;

    .wrapper {
        justify-content: flex-start;
    }
}

.radius-top {
        // border-top: none;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
}

article  tr:hover {
    td:first-child {
        color: rgb(85, 89, 92);
        font-weight: 800;
    }
    td {
        background: rgb(157, 245, 246) !important;
    }
}

tbody tr:last-child td {
    // background: red !important;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
}
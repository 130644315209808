/*-----------------------------------------
*  Pricing Page
-----------------------------------------*/

.mainPricing {
    background-image: url("../img/pricing/main-pricing-background.svg");
    background-repeat: no-repeat;
    background-size: auto;
    padding-top: 178px;
    font-family: Pt Sans;
    background-attachment: local;
    background-position: top;

    @media (max-width: 992px) {
        padding-top: 100px;
        background-size: auto 70%;
    }

    .container {
        max-width: 1170px;
        padding: 0;

        @media (max-width: 1280px) {
            max-width: 90%;
        }

        @media (max-width: 769px) {
            max-width: 95%;
        }
    }
}

.mainPricing-title {
    font-family: Montserrat;
    font-size: 56px;
    font-weight: 900;
    font-style: normal;
    color: #fff;
    max-width: 60%;
    line-height: 1.2;

    @media (max-width: 992px) {
        font-size: 40px;
    }

    @media (max-width: 769px) {
        font-size: 35px;
    }
}

.mainPricing-table {
    display: flex;
    align-items: flex-end;
    margin-top: -20px;
    margin-left: 13px;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
}

.mainPricing-table-col {
    flex-grow: 1;
    text-align: center;
    box-shadow: 0 0 14px rgba(89, 131, 255, 0.2);
    border-radius: 16px;
    position: relative;
    width: 0;

    &:first-child {
        flex-grow: 0;
        width: 260px;
        text-align: left;

        @media (max-width: 992px) {
            width: 200px;
        }
    }

    &:not(:last-child) {
        margin-right: 25px;

        @media (max-width: 992px) {
            margin-right: 10px;
        }
    }

    &--first {
        .mainPricing-table-col-line {
            padding-left: 35px;
            font-size: 18px;
            color: #727272;
            font-weight: 600;
            justify-content: left;
            background-color: #FFFFFF;

            &:nth-child(2n) {
                background-color: #F4F4F4;
            }
        }
    }

    &--easy {
        .mainPricing-table-col-head {
            background-color: #15DFA0;
            height: 60px;
            font-size: 20px;

            @media (max-width: 992px) {
                height: 40px;
                font-size: 14px;
            }
        }

        .mainPricing-table-col-img {
            fill: #15DFA0;
            stroke-width: 0;
        }
    }

    &--express {
        .mainPricing-table-col-head {
            height: 72px;
            background-color: #4373FF;
            font-size: 28px;

            @media (max-width: 992px) {
                height: 58px;
                font-size: 16px;
            }
        }

        .mainPricing-table-col-img {
            fill: #4373FF;
            stroke-width: 0;
        }
    }

    &--advanced {
        .mainPricing-table-col-head {
            height: 104px;
            background-color: #6D4DFF;
            font-size: 32px;

            @media (max-width: 992px) {
                height: 74px;
                font-size: 18px;
            }
        }

        .mainPricing-table-col-img {
            fill: #6D4DFF;
            stroke-width: 0;
        }
    }

    &--pro {
        .mainPricing-table-col-head {
            height: 172px;
            background-color: #FE7062;
            font-size: 76px;

            @media (max-width: 992px) {
                height: 90px;
                font-size: 50px;
            }
        }
    
        .mainPricing-table-col-img {
            fill: #FE7062;
            stroke-width: 0;
        }
    }
}

.mainPricing-table-col-img {
    &--cross {
        opacity: 0.25;
    }
}

.mainPricing-table-pro-img {
    position: absolute;
    right: -3px;
    top: -117px;

    @media (max-width: 992px) {
        display: none
    }
}


.mainPricing-table-col-head {
    border-radius: 16px 16px 0 0;
    color: #fff;
    font-size: 28px;
    font-weight: 700;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 0 5px 10px 5px;
}

.mainPricing-table-col-line {
    background-color: #F4F4F4;
    height: 60px;
    font-size: 16px;
    font-weight: 700;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;

    &:first-child {
        border-radius: 16px 16px 0 0;
    }

    &:last-child {
        border-radius: 0 0 16px 16px;
    }

    &:nth-child(2n) {
        background-color: #FFFFFF;
    }

    @media (max-width: 992px) {
        font-size: 14px;
    }
}

.main-info-icon {
    position: absolute;
    width: 6px;
    height: 13px;
    left: 9px;
    top: 7px;
}

.main-info-icon-button {
    position: absolute;
    left: -13px;
    top: 18px;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background-color: #ffffff;
    fill: #5983FF;
    box-shadow: 0px 0px 6px rgba(89, 131, 255, 0.2);

    &:hover {
        background-color: #1DBC00;
        fill: #ffffff;
    }
}

.main-info-icon-tooltip::after {
    content: '';
    position: absolute;
    left: 0px;
    top: -25px;
    z-index: 1;
    border-style: solid;
    border-width: 35px 0 0 25px;
    border-color: transparent transparent transparent #3C3C3C;
}

.main-info-icon-button .main-info-icon-tooltip {
    visibility: hidden;
    display: none;
    font-size: 14px;
}

.main-info-icon-button:hover .main-info-icon-tooltip {
    visibility: visible;
    display: block;
    width: 260px;
    height: auto;
    background: #3C3C3C;
    color: #FFFFFF;
    padding: 25px;
    border-radius: 10px;
    font-weight: 300;
    position: absolute;
    left: 13px;
    top: 50px;
    z-index: 10;
}

/*-----------------------------------------
*  Pricing Page functionalPricing
-----------------------------------------*/

.functionalPricing {
    background-image: url("../img/pricing/functional-pricing-background.svg");
    background-repeat: no-repeat;
    background-size: auto;
    padding-top: 236px;
    font-family: Pt Sans;
    background-position: top;
    margin-top: -50px;

    @media (max-width: 1280px) {
        padding-top: 200px;
    }
    
    @media (max-width: 992px) {
        padding-top: 150px;
        background-size: 200%;
    }
    
    @media (max-width: 769px) {
        padding-top: 100px;
    }

    .container {
        max-width: 1170px;
        padding: 0;

        @media (max-width: 1280px) {
            max-width: 90%;
        }

        @media (max-width: 769px) {
            max-width: 95%;
        }
    }
}

.functionalPricing-title {
    font-family: Montserrat;
    font-size: 34px;
    font-weight: 900;
    font-style: normal;
    color: #444444;
    max-width: 550px;
    line-height: 41px;
    max-width: 600px;

    @media (max-width: 992px) {
        font-size: 32px;
        line-height: 38px;
        padding: 10px 0 20px;
    }

    @media (max-width: 520px) {
        font-size: 35px;
    }
}

.functionalPricing-info {
    padding: 30px 0 60px 0;
    font-family: 'Pt Sans';
    font-style: normal;
    font-weight: 400;
    color: #444444;
    font-size: 24px;
    line-height: 29px;
    min-height: 120px;
    max-width: 600px;

    @media (max-width: 992px) {
        font-size: 17px;
        min-height: 100px;
        padding-top: 20px;
        padding-bottom: 20px;
        line-height: 25px;
        max-width: 450px;
    }

    @media (max-width: 769px) {
        padding-top: 10px;
        padding-bottom: 20px;
        max-width: 300px;
    }
}

.pricing-request-button {
    border: 0px;
    border-radius: 6px;
    background-color: #0073F4;
    width: 225px;
    height: 55px;
    justify-content: center;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;

    color: #ffffff;
    display: flex;
    align-items: center;
    text-align: center;

    &--white {
        background-color: #FCFCFC;
        color: #4B4EF7;
    }

    &:hover{
        color: #ffffff;

        &.pricing-request-button--white {
            color: #4B4EF7;
        }
    }

    @media (max-width: 992px) {
        height: 40px;
    }
}

.functionalPricing-table {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    font-family: "Pt Sans";
    font-weight: 700;
    font-style: normal;
    line-height: normal;
    text-align: center;
    padding: 97px 0 0 10px;
    @media (max-width: 769px) {
        padding: 50px 0 0 10px;
    }
}

.comparison .functionalPricing-table-col {
        &:not(:last-child) {
            margin-right: 5px;
        }

        div {
            &:first-child {
            font-size: 24px;
            font-family: Montserrat;
            font-size: 24px;
            font-weight: 600;
            line-height: 29px;
            letter-spacing: 0em;
            text-align: center;
        }
    }
   
}

.functionalPricing-table-col {
    flex-grow: 1;
    flex-shrink: 1;
    position: relative;

    &:first-child {
        flex-grow: 0;
        flex-shrink: 0;
    }

    &:not(:last-child) {
        margin-right: 18px;

        @media (max-width: 769px) {
            margin-right: 0;
        }
    }

    @media (max-width: 769px) {
        margin-right: 0;
    }

    &--first {
        .functionalPricing-table-col-line {
            justify-content: left;

            &--comparison {
                background-color: #6D4DFF;
                color: #ffffff;
            }

            @media (max-width: 992px) {
                padding:  0 15px 0 25px;
            }
        }
    }

    &--easy {
        .functionalPricing-table-col-head {
            color: #15DFA0;
        }

        .functionalPricing-table-col-img {
            fill: #15DFA0;
            stroke-width: 0;
        }
    }

    &--express {
        .functionalPricing-table-col-head {
            color: #5983FF;
        }

        .functionalPricing-table-col-img {
            fill: #5983FF;
            stroke-width: 0;
        }
    }

    &--advanced {
        .functionalPricing-table-col-head {
            color: #6D4DFF;
        }

        .functionalPricing-table-col-img {
            fill: #6D4DFF;
            stroke-width: 0;
        }
    }

    &--pro {
        .functionalPricing-table-col-head {
            color: #FE7062;
        }

        .functionalPricing-table-col-img {
            fill: #FE7062;
            stroke-width: 0;
        }
    }
}

.functionalPricing-table-col-img {
    &--cross {
        opacity: 0.25;
    }
}

.functionalPricing-table-col-head {
    font-size: 24px;
    display: flex;
    justify-content: center;
    padding-bottom: 15px;

    @media (max-width: 992px) {
        font-size: 18px;
    }
}

.functionalPricing-table-col-line {
    padding:  0 35px;
    background-color: #fff;
    min-height: 60px;
    font-size: 16px;
    display: flex;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-bottom-width: 0px;
    border-color: #D7D7D7;
    justify-content: center;
    position: relative;

    &--comparison {
        justify-content: flex-start;
    }

    &:first-child {
        border-radius: 16px 16px 0 0;
    }

    &:last-child {
        border-radius: 0 0 16px 16px;
        border-bottom-width: 1px;
    }

    @media (max-width: 1280px) {
        padding:  0 25px;
    }
    
    @media (max-width: 992px) {
        padding:  0 15px;
        height: 50px;
    }

    &--comparison {
        padding:  0 10px;
        font-family: Proxima Nova;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        text-align: left;

    }
}

.info-icon {
    position: absolute;
    width: 6px;
    height: 10px;
    left: 6px;
    top: 5px;
}

.info-icon-button {
    position: absolute;
    left: -11px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border-style: solid;
    border-width: 0.5px;
    border-color: #D1D1D1;
    background-color: #ffffff;
    fill: #727272;

    &:hover {
        background-color: #1DBC00;
        fill: #ffffff;
    }
}

.info-icon-tooltip::after {
    content: '';
    position: absolute;
    left: 0px;
    top: -25px;
    z-index: 1;
    border-style: solid;
    border-width: 35px 0 0 25px;
    border-color: transparent transparent transparent #3C3C3C;
}

.info-icon-button .info-icon-tooltip {
    visibility: hidden;
    display: none;
    font-size: 14px;
}

.info-icon-button:hover .info-icon-tooltip {
    visibility: visible;
    display: block;
    width: 260px;
    height: auto;
    background: #3C3C3C;
    color: #FFFFFF;
    padding: 25px;
    border-radius: 10px;
    font-weight: 300;
    position: absolute;
    left: 10px;
    top: 45px;
    z-index: 10;
}

/*-----------------------------------------
*  Pricing Page Blog and Cards
-----------------------------------------*/

.blogPricing {
    background-image: url("../img/pricing/blog-pricing-background.svg");
    background-repeat: no-repeat;
    background-size: auto;
    background-position: top;
    padding-top: 217px;
    margin-top: 100px;
    min-height: 1450px;

    .container {
        max-width: 1170px;
        padding: 0;

        @media (max-width: 1280px) {
            max-width: 90%;
        }

        @media (max-width: 769px) {
            max-width: 95%;
        }
    }

    @media (max-width: 1280px) {
        padding-top: 100px;
    }

    @media (max-width: 992px) {
        margin-top: 60px;
    }
}

.blogPricing-title {
    font-family: Montserrat;
    font-size: 42px;
    font-weight: 900;
    font-style: normal;
    color: #444444;
    max-width: 70%;
    line-height: 50px;

    @media (max-width: 992px) {
        font-size: 32px;
        line-height: 40px;
    }
}

.blogPricing-info {
    padding: 20px 0 60px 0;
    font-family: 'Pt Sans';
    font-style: normal;
    font-weight: 400;
    color: #444444;
    font-size: 20px;
    line-height: 29px;
    max-width: 60%;

    @media (max-width: 992px) {
        font-size: 17px;
        width: auto;
        padding-top: 20px;
        padding-bottom: 20px;
        line-height: 25px;
    }
}

.comparison-container {
    display: flex;
    padding-top: 80px;
    flex-direction: column;
    flex-wrap: nowrap;

    @media (max-width: 769px) {
        padding-top: 40px;
    }

    .comparison-post{
        display: flex;
        background-color: #fff;
        background: #FFFFFF;
        box-shadow: 0px 4px 10px rgba(117, 117, 117, 0.11);
        border-radius: 16px;
        margin-bottom: 20px;
        position: relative;

        .header-with-icons {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;

            span.material-icons {
                font-family: 'Material Icons' !important;
                display: flex;
                white-space: nowrap;
                align-content: center;
                justify-content: center;
                align-items: center;
                font-size: 45px;
                color: #4977ff;
                padding: 0;
                padding-right: 45px;

                @media (max-width: 900px) {
                    padding-right: 20px;
                }
            }

            h3 {
                font-family: Montserrat;
                font-size: 26px;
                font-weight: 700;
                line-height: 31px;
                margin-bottom: 0 !important;
            }
        }

        .comparison-post-text {
            padding: 15px 30px 15px 30px;
            color: #444444;
            font-family: PT Sans;
            font-size: 16px;
            font-weight: 700;
            line-height: 22px;

            @media (max-width: 1200px) {
                padding: 35px 35px;
            }

            @media (max-width: 992px) {
                padding: 25px 10px 25px 25px;
            }

            @media (max-width: 520px) {
                padding: 15px 10px 15px 25px;
            }

            h3 {
                font-family: Montserrat;
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                color: #444444;

                @media (max-width: 767px) {
                    font-size: 20px;
                }
            }

            h4 {
                font-family: Proxima Nova;
                font-weight: 600;
                font-size: 18px;

                @media (max-width: 520px) {
                    padding-top: 0px;
                }
            }

            a {
                font-family: Proxima Nova;
                color: #2A8FEC;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                text-decoration: underline;
            }

            p {
                display: inline-block;
                padding-top: 15px;

                @media (max-width: 1200px) {
                    padding-top: 10px;
                }

                @media (max-width: 992px) {
                    padding-top: 7px;
                }
            }

            p.bold {
                font-size: 20px;
                font-weight: 600;
                color: rgb(68, 68, 68);
                // opacity: 0.4;
            }

        }
    }
}

.blogPricing-container {
    min-height: 1320px;
    display: block;
    columns: 500px auto;
    column-gap: 50px;
    padding-top: 100px;

    @media (max-width: 1280px) {
        padding-top: 70px;
        columns: 300px auto;
        column-gap: 40px;
    }

    @media (max-width: 992px) {
        padding-top: 50px;
    }

    @media (max-width: 769px) {
        column-gap: 30px;
    }
}

.blogPricing-container-post {
    font-family: 'Pt Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    display: flex;
    background-color: #fff;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(117, 117, 117, 0.11);
    border-radius: 16px;
    margin-bottom: 50px;
    position: relative;
    float: left;
    page-break-inside: avoid;
    break-inside: avoid;
    margin-top: 5px;

    @media (max-width: 1200px) {
        margin-bottom: 35px;
    }

    @media (max-width: 992px) {
        margin-bottom: 25px;
    }

    &--purple {
        .blogPricing-container-post-text-colored-rectangle {
            background: #AC8BFF;
        }

        .blogPricing-container-post-text-header, h4 {
            color: #AC8BFF;
        }
    }

    &--purple-hard {
        .blogPricing-container-post-text-colored-rectangle {
            background: #8458FF;
        }

        .blogPricing-container-post-text-header, h4 {
            color: #8458FF;
        }
    }

    &--green {
        .blogPricing-container-post-text-colored-rectangle {
            background: #1DBC00;
        }

        h4 {
            color: #1DBC00;
        }
    }

    &--orange {
        .blogPricing-container-post-text-colored-rectangle {
            background: #FDCD56;
        }

        h4 {
            color: #FDCD56;
        }
    }

    &--rose {
        .blogPricing-container-post-text-colored-rectangle {
            background: #D971B0;
        }

        h4 {
            color: #D971B0;
        }
    }

    &--aquamarine {
        .blogPricing-container-post-text-colored-rectangle {
            background: #72FBCF;
        }

        h4 {
            color: #72FBCF;
        }
    }

    
    &--blue {
        .blogPricing-container-post-text-colored-rectangle {
            background: #0073F4;
        }

        h4 {
            color: #0073F4;
        }
    }
}

.blogPricing-container-post-text-colored-rectangle {
    position: absolute;
    width: 10px;
    height: 82px;
    left: 0px;
    top: 45px;
    border-radius: 2px;

    @media (max-width: 992px) {
        top: 20px;
    }

    @media (max-width: 769px) {
        width: 7px;
    }
}

.blogPricing-container-post-text {
    padding: 50px 50px;
    font-size: 16px;
    color: #6A6A6A;

    @media (max-width: 1200px) {
        padding: 35px 35px;
    }

    @media (max-width: 992px) {
        padding: 25px 10px 25px 25px;
    }

    @media (max-width: 520px) {
        padding: 15px 10px 15px 25px;
    }

    h3 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        color: #444444;

        @media (max-width: 769px) {
            font-size: 20px;
        }
    }

    h4 {
        font-family: Pt Sans;
        font-weight: 600;
        font-size: 18px;

        @media (max-width: 520px) {
            padding-top: 0px;
        }
    }

    a {
        font-family: Pt Sans;
        color: #2A8FEC;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        text-decoration: underline;
    }

    span {
        display: inline-block;
        padding: 15px 0 15px 0;
        font-family: Pt Sans;
        color: #6A6A6A;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;

        @media (max-width: 1200px) {
            padding: 10px 0;
        }

        @media (max-width: 992px) {
            padding: 7px 0;
        }
    }
}

@import 'fonts';
@import "margins.scss";
@import "footer.scss";
@import "video-player.scss";
@import "pricing-page.scss";
@import "front-page.scss";
@import "gallery.scss";
@import "demo-request-form.scss";
@import "business-case-template.scss";
@import "cards-swiper.scss";

#loading {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #151515;
    z-index: 999999;
}

a.disabled {
    opacity: .5;
    pointer-events: none;
    cursor: default;
    filter: grayscale(100%);
}

.load-circle {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    animation: rotate 1s infinite;
    height: 50px;
    width: 50px;

    &:before, &:after {
        border-radius: 50%;
        content: '';
        display: block;
        height: 20px;
        width: 20px;
    }

    &:before {
        animation: ball1 1s infinite;
        background-color: #ffffff;
        box-shadow: 30px 0 0 #0073F4;
        margin-bottom: 10px;
    }

    &:after {
        animation: ball2 1s infinite;
        background-color: #0073F4;
        box-shadow: 30px 0 0 #fdcd56;
    }
}

@keyframes rotate {
    0% {
        -webkit-transform: rotate(0deg) scale(0.8);
        -moz-transform: rotate(0deg) scale(0.8);
    }

    50% {
        -webkit-transform: rotate(360deg) scale(1.2);
        -moz-transform: rotate(360deg) scale(1.2);
    }

    100% {
        -webkit-transform: rotate(720deg) scale(0.8);
        -moz-transform: rotate(720deg) scale(0.8);
    }
}

@keyframes ball1 {
    0% {
        box-shadow: 30px 0 0 #72FBCF;
    }

    50% {
        box-shadow: 0 0 0 #72FBCF;
        margin-bottom: 0;
        -webkit-transform: translate(15px, 15px);
        -moz-transform: translate(15px, 15px);
    }

    100% {
        box-shadow: 30px 0 0 #72FBCF;
        margin-bottom: 10px;
    }
}

@keyframes ball2 {
    0% {
        box-shadow: 30px 0 0 #34D3F1;
    }

    50% {
        box-shadow: 0 0 0 #34D3F1;
        margin-top: -20px;
        -webkit-transform: translate(15px, 15px);
        -moz-transform: translate(15px, 15px);
    }

    100% {
        box-shadow: 30px 0 0 #34D3F1;
        margin-top: 0;
    }
}

/*---------------------------------
* 02. Common
-----------------------------------*/

html {
    overflow-x: hidden;
}

body {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    line-height: 1.8;
    color: #55595c;
    // font-smoothing: antialiased;
    font-weight: 400;
    overflow-x: hidden;
    letter-spacing: 0.5px;

    &.customize-support {
        margin: 0 !important;

        .navbar {
            top: 32px;
        }
    }
}

a {
    text-decoration: none !important;
}

img {
    max-width: 100%;
}

.container-large {
    max-width: 1440px;
}

.section {
    padding: 90px 0;
}

@media (max-width: 992px) {
    .section {
        padding: 60px 0;
    }
}

@media (max-width: 767px) {
    .section {
        padding: 40px 0;
    }
}

/*-----------------------------------------
*  shapes Properties
-----------------------------------------*/

.shapes-section {
    position: relative;
    overflow: hidden;
}

.shapes-box {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;

    span {
        position: absolute;

        &:nth-child(1) {
            left: 4.8%;
            top: 4.5%;
        }

        &:nth-child(2) {
            left: 9.6%;
            top: 27.2%;
        }

        &:nth-child(3) {
            left: 8.8%;
            bottom: 16%;
        }

        &:nth-child(4) {
            left: 25%;
            bottom: 21%;
        }

        &:nth-child(5) {
            left: 35%;
            top: 6.6%;
        }

        &:nth-child(6) {
            left: 43%;
            bottom: 30%;
        }

        &:nth-child(7) {
            left: 49%;
            top: 50%;
        }

        &:nth-child(8) {
            right: 11.8%;
            top: 33%;
        }

        &:nth-child(9) {
            right: 6%;
            top: 10%;
        }

        &:nth-child(10) {
            right: 4.5%;
            bottom: 33%;
        }
    }
}

/*---------------------------------
* Buttons
-----------------------------------*/

.btn {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 2px;
    padding: 12px 28px;
    border-radius: 5px;
    border: none;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);

    &:hover {
        box-shadow: 0px 20px 24px 0px rgba(0, 11, 40, 0.1);
    }
}

.btn-white {
    border: 1px solid #ffffff;
    color: #323232;
    background: #ffffff;

    &:hover {
        background: #ffffff;
        color: #323232;
    }
}

.btn-theme, .btn-theme-2nd {
    color: #ffffff;

    &:hover {
        color: #ffffff;
    }
}

.border-radius {
    border-radius: 50%;
}

.border-radius-01 {
    border-radius: 50% 0 50% 50%;
}

.border-radius-02 {
    border-radius: 90% / 40%;
}

.icon-s {
    width: 40px;
    height: 40px;
    display: inline-block;
    line-height: 40px;
    text-align: center;
    color: #ffffff;
    font-size: 18px;
}

.icon-m {
    width: 60px;
    height: 60px;
    display: inline-block;
    line-height: 60px;
    text-align: center;
    color: #ffffff;
    font-size: 22px;
}

.icon-l {
    width: 80px;
    height: 80px;
    display: inline-block;
    line-height: 80px;
    text-align: center;
    color: #ffffff;
    font-size: 28px;
}

.icon-xl {
    width: 100px;
    height: 100px;
    display: inline-block;
    line-height: 100px;
    text-align: center;
    color: #ffffff;
    font-size: 40px;
}

/*---------------------------------
* Section titles
-----------------------------------*/

.section-title {
    h6 {
        font-size: 16px;
        margin: 0 0 15px;
    }

    h2 {
        font-weight: 700;
        margin: 0;
        font-size: 32px;
        position: relative;
        padding: 0 0 20px;
    }

    p {
        font-size: 16px;
        margin: 0;
    }
}

.side-title {
    h6 {
        margin: 0 0 15px;
    }

    h2 {
        font-size: 40px;
        font-weight: 400;
        margin: 0 0 25px;
    }

    .large-text {
        width: 450px;
        max-width: 100%;
    }

    &.white h2 {
        color: #ffffff;
    }
}

@media (max-width: 992px) {
    .side-title h2 {
        font-size: 30px;
    }
}

@media (max-width: 767px) {
    .section-title {
        h6 {
            font-size: 14px;
            margin: 0 0 15px;
        }

        h2 {
            font-size: 27px;

            br {
                display: none;
            }
        }
    }

    .side-title h2 {
        font-size: 24px;
        margin: 0 0 20px;
    }
}

/*---------------------------------
* Owl
-----------------------------------*/

.owl-dots {
    text-align: center;

    .owl-dot {
        display: inline-block;
        vertical-align: top;
        margin-right: 8px;

        span {
            width: 15px;
            height: 15px;
            border: 2px solid #323232;
            display: inline-block;
            border-radius: 50%;
            background: #ffffff;
        }

        &.active span {
            background: #323232;
        }
    }
}

/*---------------------------------
* Shadow
-----------------------------------*/

.hover-shadow:hover, .box-shadow {
    box-shadow: 0 8px 50px -6px rgba(84, 84, 120, 0.26);
}

/*---------------------------------
* Base color
-----------------------------------*/

.dark-color {
    color: #111111;
}

.gray-bg {
    background: #fcfcfc;
}

.gray-bg-g {
    background-image: linear-gradient(0deg, #fcfcfc 0%, #ffffff 100%);
}

.large-text {
    font-size: 16px;
}

@media (max-width: 767px) {
    .large-text {
        font-size: 14px;
    }
}

/*---------------------------------
* Header
-----------------------------------*/

.navbar {
    &--transparent {
        background: none !important;
    }
}

.header-nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    padding-top: 0;
    padding-bottom: 0;
    box-shadow: 0px 4px 14px rgba(117, 117, 117, 0.15);

    a {
        transition: ease all 0.3s;
        -webkit-transition: ease all 0.3s;
    }

    .dark-logo {
        display: none;
    }

    .navbar-nav {
        flex-wrap: wrap;

        .nav-link {
            color: #ffffff;
            font-size: 14px;
            font-weight: 500;
            padding: 0 12px;
            position: relative;
            line-height: 4;
            text-align: center;
            // padding-bottom: 25px;
            // margin-top: 20px;
            // margin-bottom: 20px;

            @media (max-width: 1280px) {
                padding: 0 10px;
            }

            &:before {
                content: "";
                width: 0;
                height: 1px;
                display: inline-block;
                vertical-align: top;
                background: #ffffff;
                transition: ease all 0.3s;
                -webkit-transition: ease all 0.3s;
                position: absolute;
                bottom: 15px;
                left: 12px;
            }

            &--unique {
                font-weight: 600;
                color: #0073F4 !important;
            }
        }

        li:hover .nav-link:before, .nav-link.active:before {
            width: 50%;
        }
    }

    .navbar-brand {
        font-size: 24px;
        font-weight: 700;
        color: #000000 !important;
        position: relative;
        top: -2px;
        padding: 0;

        img {
            height: 35px;
        }
    }
}

.fixed-header .header-nav {
    background: #ffffff !important;
    box-shadow: 0 8px 50px -6px rgba(84, 84, 120, 0.26);

    .navbar-nav .nav-link, .navbar-brand {
        color: #323232;
    }

    .navbar-nav .nav-link:before {
        background: #000000;
    }

    .light-logo {
        display: none;
    }

    .dark-logo {
        display: block;
    }

    a.btn {
        color: #ffffff;
    }
}

.header-nav-dark {
    .navbar-nav .nav-link {
        color: #323232;

        &:before {
            background: #323232;
        }
    }

    .navbar-brand {
        color: #323232;
    }
}

.header-nav-style-1 {
    top: 0;
    width: 1170px;
    margin: 0 auto;
    background: #ffffff;
    border-radius: 0px 0px 10px 10px;
    max-width: 100%;
}

.header-nav-style-2 .navbar-nav .nav-link {
    font-size: 15px;
}

/*Navbar Toogle*/

.navbar-toggler {
    border: none;
    padding: 10px 6px;
    outline: none !important;

    span {
        display: block;
        width: 22px;
        height: 2px;
        border-radius: 1px;
        background: #ffffff;

        + span {
            margin-top: 4px;
            width: 18px;

            + span {
                width: 10px;
            }
        }
    }
}

.fixed-header .navbar-toggler span, .header-nav-dark .navbar-toggler span {
    background: #323232;
}

/**/

@media (max-width: 992px) {
    .header-nav {
        padding-top: 10px;
        padding-bottom: 10px;

        .navbar-nav {
            padding: 20px 0;
            max-height: 70vh;
            overflow-y: auto;

            li {
                width: 95%;
            }

            .nav-link {
                display: inline-block;
                padding: 10px 0;
                line-height: normal;

                &:before {
                    bottom: 10px;
                    left: 0;
                }
            }

            .nav-link-btn {
                margin-top: 12px;
            }
        }
    }

    /*Header Color*/
    .navbar-toggler span {
        background: #323232;
    }

    .header-nav {
        background: #ffffff;

        .navbar-nav .nav-link, .navbar-brand {
            color: #323232;
        }

        .navbar-nav .nav-link:before {
            background: #000000;
        }

        .light-logo {
            display: none;
        }

        .dark-logo {
            display: block;
        }

        a.btn {
            color: #ffffff;
        }
    }

    /**/
}

/*===================================
  01. Mega Menu
====================================== */

.m-dropdown-mega-menu {
    width: 100%;
    padding: 30px 35px;
    background: #ffffff;
    list-style-type: none;
    border-top: 1px solid #eeeeee;
}

.mm-column {
    ul {
        &:first-child {
            margin-top: 0;
        }

        margin: 15px 0 0 0;
        padding: 0;
    }

    li {
        list-style-type: none;
        list-style-type: none;
        padding: 8px 0;
        display: inline-block;
        vertical-align: top;
        width: 100%;

        &.mm-header {
            display: inline-block;
            font-size: 15px;
            font-weight: 500;
            margin-bottom: 12px;
            white-space: inherit;
            padding: 0 0 10px 0;
            border-bottom: 1px solid #eeeeee;

            > a {
                font-size: 16px;
                padding: 0;
            }
        }

        a {
            text-align: left;
            font-size: 14px;
            color: #051441;
            margin: 0;
            display: block;
            position: relative;
            left: 0;
            padding: 0 0 0 15px;
            line-height: normal;
        }

        &:not(.mm-header) a:after {
            content: "";
            width: 5px;
            height: 5px;
            display: inline-block;
            position: absolute;
            top: 8px;
            left: 0;
            border-radius: 50%;
        }

        &:hover a {
            text-decoration: none;
        }

        a.active {
            color: #000000;
        }
    }
}

/* Singale Menu */

.m-dropdown {
    position: relative;
    align-items: center;
    @media (min-width: 992px) {
        display: inline-flex;
    }

    &__icon {
        // width: 100%;
        height: 20px;
        @media (max-width: 992px) {
            margin-right: 10px;
        }
    }

    &--lang {
        margin-left: 20px;
    }
}

.m-dropdown-menu-item {
    &__icon {
        width: 100%;
        max-width: 20px;
        margin-right: 12px;
        flex-shrink: 0;
    }

    &__text {
        flex-shrink: 0;
    }
}

.m-dropdown-multilevel .m-dropdown-menu {
    margin: 0;
    padding: 13px 0 15px;
    background: #ffffff;
    width: 100%;

    li {
        list-style-type: none;
        position: relative;
        display: flex;
        align-items: center;

        a {
            text-align: left;
            font-size: 14px;
            color: #051441;
            text-transform: capitalize;
            margin: 0;
            display: flex;
            align-items: center;
            position: relative;
            left: 0;
            font-weight: 500;
            @media (min-width: 992px) {
                padding-left: 20px;
                padding-right: 20px;
            }

            &:hover {
                color: #000000;
            }
        }
    }

    ul {
        margin: 0;
        padding: 13px 0 15px;
        background: #ffffff;
        width: 100%;
    }
}

@media (min-width: 992px) {
    .m-dropdown > a::after {
        display: inline-block;
        width: 0px;
        height: 0px;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: "";
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0px;
        border-left: 0.3em solid transparent;
    }

    .m-dropdown-multilevel .m-dropdown-menu > li > ul {
        -webkit-transition: all 400ms ease 0s;
        transition: all 400ms ease 0s;
        -webkit-transform: rotateX(90deg);
        transform: rotateX(90deg);
        -webkit-transform-origin: 0 0;
        -ms-transform-origin: 0 0;
        transform-origin: 0 0;

        > li > ul {
            -webkit-transition: all 400ms ease 0s;
            transition: all 400ms ease 0s;
            -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg);
            -webkit-transform-origin: 0 0;
            -ms-transform-origin: 0 0;
            transform-origin: 0 0;

            > li > ul {
                -webkit-transition: all 400ms ease 0s;
                transition: all 400ms ease 0s;
                -webkit-transform: rotateX(90deg);
                transform: rotateX(90deg);
                -webkit-transform-origin: 0 0;
                -ms-transform-origin: 0 0;
                transform-origin: 0 0;
            }
        }
    }

    .m-dropdown .m-dropdown-menu {
        -webkit-transition: all 400ms ease 0s;
        transition: all 400ms ease 0s;
        -webkit-transform: rotateX(90deg);
        transform: rotateX(90deg);
        -webkit-transform-origin: 0 0;
        -ms-transform-origin: 0 0;
        transform-origin: 0 0;
    }

    .m-dropdown-multilevel .m-dropdown-menu > li {
        &:hover > ul {
            -webkit-transition-delay: 200ms !important;
            transition-delay: 200ms !important;
            -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
            opacity: 1;
            visibility: visible;
        }

        > ul > li {
            &:hover > ul, > ul > li:hover > ul {
                -webkit-transition-delay: 200ms !important;
                transition-delay: 200ms !important;
                -webkit-transform: rotateX(0deg);
                transform: rotateX(0deg);
                opacity: 1;
                visibility: visible;
            }
        }
    }

    .m-dropdown {
        &:hover .m-dropdown-menu {
            -webkit-transition-delay: 200ms !important;
            transition-delay: 200ms !important;
            -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
            opacity: 1;
            visibility: visible;
        }

        .m-dropdown-toggle {
            display: none;
        }
    }

    /*Mega menu */
    .m-mega-menu {
        position: static !important;
    }

    .m-dropdown-mega-menu {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 50;
        box-shadow: 0 8px 20px -8px rgba(84, 84, 120, 0.26);
    }

    .m-dropdown-multilevel .m-dropdown-menu {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 50;
        width: 170px;
        box-shadow: 0 8px 20px -8px rgba(84, 84, 120, 0.26);

        li.hover-trigger > a i {
            position: absolute;
            right: 0;
            font-size: 12px;
            margin-top: 0;
            height: 20px;
            width: 20px;
            line-height: 20px;
            text-align: center;
            top: 50%;
            transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
        }

        > li {
            > ul > li {
                > ul > li {
                    > ul > li:hover > a, &:hover > a {
                        left: 4px;
                        color: #000000;
                        text-decoration: none;
                    }
                }

                &:hover > a {
                    left: 4px;
                    color: #000000;
                    text-decoration: none;
                }
            }

            &:hover > a {
                left: 4px;
                color: #000000;
                text-decoration: none;
            }

            a.active {
                color: #000000;
            }
        }

        ul {
            position: absolute;
            top: 0;
            left: 100%;
            z-index: 50;
            width: 220px;
        }

        > li > ul {
            position: absolute;
            top: 0;
            left: 100%;
        }
    }
}

/*@media*/

@media (max-width: 992px) {
    .m-dropdown {
        .m-dropdown-toggle {
            display: inline-block;
            position: absolute;
            right: 0;
            top: 0;
            color: #323232;
            height: 36px;
            width: 36px;
            text-align: center;
            line-height: 36px;
        }

        a .fa-angle-down {
            display: none;
        }

        .m-dropdown-menu {
            padding: 8px 15px;
            display: none;
            background: #f5f5f5;
        }

        .m-dropdown-mega-menu {
            padding: 15px 0;
        }

        .m-dropdown-menu.open-menu {
            display: block;
        }
    }

    .m-dropdown-multilevel .m-dropdown-menu {
        li {
            padding-left: 0;
            padding-right: 0;
            position: relative;

            &.hover-trigger {
                margin-bottom: 10px;

                > ul > li {
                    border-top: 1px solid rgba(255, 255, 255, 0.07);
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }

            a .arrow {
                display: none;
            }

            li {
                padding-left: 0;
                padding-right: 0;
            }
        }

        ul {
            padding: 0;
            float: none;
            background: #222222;

            &.open-menu {
                display: block;
                border-bottom: 2px solid rgba(255, 255, 255, 0.1);
                padding: 0 0 0px 10px;
                margin-bottom: 15px;
            }
        }

        .open-menu-parent > a {
            color: #ffffff;
        }
    }

    .mm-column {
        margin-bottom: 15px;

        &:last-child {
            margin-bottom: 0;
        }

        li {
            border-bottom: 1px solid rgba(255, 255, 255, 0.07);
        }
    }
}

/*@media*/

/*-----------------------------------------
*  Bg Baner effect
-----------------------------------------*/

.bg-effect {
    position: relative;

    .bg-effect-bottom {
        position: absolute;
        bottom: -2px;
        left: -1px;
        right: -1px;

        img {
            width: 100%;
            min-width: 1440px;
        }
    }
}

.ripple, .ripple2, .ripple3 {
    border-radius: 9999px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.4);
    position: absolute;
    bottom: 50%;
    margin-bottom: -326px;
    right: 50%;
    margin-right: -326px;
    -webkit-animation: shrink 5s linear infinite;
    -moz-animation: shrink 5s linear infinite;
    -o-animation: shrink 5s linear infinite;
    animation: shrink 5s linear infinite;
    z-index: 0;
    background: rgba(255, 255, 255, 0.4);
}

.ripple {
    -webkit-animation-delay: 1s;
    -moz-animation-delay: 1s;
    -o-animation-delay: 1s;
    animation-delay: 1s;
}

.ripple2 {
    -webkit-animation-delay: 1.5s;
    -moz-animation-delay: 1.5s;
    -o-animation-delay: 1.5s;
    animation-delay: 1.5s;
}

.ripple3 {
    -webkit-animation-delay: 2s;
    -moz-animation-delay: 2s;
    -o-animation-delay: 2s;
    animation-delay: 2s;
}

@-webkit-keyframes shrink {
    0% {
        margin-bottom: 0;
        margin-right: 0;
        width: 0;
        height: 0;
        opacity: 0;
    }

    10% {
        opacity: 0.5;
    }

    30% {
        opacity: 0.3;
    }

    60% {
        opacity: 0;
    }

    100% {
        margin-bottom: -326px;
        margin-right: -326px;
        width: 650px;
        height: 650px;
        opacity: 0;
    }
}

@-moz-keyframes shrink {
    0% {
        margin-bottom: 0;
        margin-right: 0;
        width: 0;
        height: 0;
        opacity: 0;
    }

    10% {
        opacity: 0.5;
    }

    30% {
        opacity: 0.3;
    }

    60% {
        opacity: 0;
    }

    100% {
        margin-bottom: -326px;
        margin-right: -326px;
        width: 650px;
        height: 650px;
        opacity: 0;
    }
}

@-o-keyframes shrink {
    0% {
        margin-bottom: 0;
        margin-right: 0;
        width: 0;
        height: 0;
        opacity: 0;
    }

    10% {
        opacity: 0.5;
    }

    30% {
        opacity: 0.3;
    }

    60% {
        opacity: 0;
    }

    100% {
        margin-bottom: -326px;
        margin-right: -326px;
        width: 650px;
        height: 650px;
        opacity: 0;
    }
}

@keyframes shrink {
    0% {
        margin-bottom: 0;
        margin-right: 0;
        width: 0;
        height: 0;
        opacity: 0;
    }

    10% {
        opacity: 0.5;
    }

    30% {
        opacity: 0.3;
    }

    60% {
        opacity: 0;
    }

    100% {
        margin-bottom: -326px;
        margin-right: -326px;
        width: 650px;
        height: 650px;
        opacity: 0;
    }
}

/*---------------------------------
* 06. Page Title
-----------------------------------*/

.page-title-section {
    padding: 160px 0 80px;
    position: relative;
}

.page-title h1 {
    color: #ffffff;
    margin: 0 0 15px;
    font-size: 40px;
    text-align: center;
    font-weight: 600;
}

.page-title-section .breadcrumb {
    padding: 0;
    margin: 0;
    background: none;
    text-align: center;

    li {
        color: rgba(255, 255, 255, 0.58);
        font-size: 14px;
        margin: 0 15px;
        position: relative;

        &:not(:first-child):after {
            width: 5px;
            height: 5px;
            background: #ffffff;
            left: -15px;
            content: "";
            float: left;
            position: absolute;
            top: 9px;
            border-radius: 50%;
        }
    }

    a {
        color: #ffffff;
        text-decoration: none;
    }
}

@media (max-width: 992px) {
    .page-title-section {
        padding: 100px 0 40px;
    }
}

/*-----------------------------------------
*  Home banner
-----------------------------------------*/

.particles-effect {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.3;
}

.full-screen {
    min-height: 100vh;
}

.home-banner {
    padding: 150px 0 0;

    .banner-text {
        position: relative;
        z-index: 1;
    }

    h2 {
        font-size: 50px;
        color: #ffffff;
        font-weight: 700;
        text-transform: capitalize;
        margin: 0 0 25px;
    }

    p {
        font-size: 16px;
        color: #ffffff;
        opacity: 0.8;
        margin: 0;
    }

    .btn-bar {
        margin-top: 30px;
    }

    .banner-img {
        position: relative;
        z-index: 1;
    }
}

.home-banner-01 {
    overflow: hidden;
    background-image: linear-gradient(0deg, #ededef 0%, #ffffff 100%);

    .banner-text {
        padding: 150px 0 50px;
    }

    h2 {
        font-size: 40px;
        font-weight: 700;
        text-transform: capitalize;
        margin: 0 0 20px;
    }

    p {
        font-size: 16px;
        opacity: 0.8;
        margin: 0;
    }

    .btn-bar {
        margin-top: 30px;
    }
}

.home-banner-02 {
    position: relative;

    .home-efftect {
        width: 100%;
        position: absolute;
        bottom: 0;
        right: 0;
        height: 100%;

        img {
            width: 100%;
            min-width: 1800px;
        }
    }

    .banner-text {
        position: relative;
        z-index: 1;
    }

    h2 {
        font-size: 45px;
        color: #323232;
        font-weight: 500;
        text-transform: capitalize;
        margin: 0 0 30px;
    }

    p {
        font-size: 16px;
        margin: 0;
    }

    .btn-bar {
        margin-top: 40px;
    }
}

.container-header .container {
    max-width: 1200px !important;
    padding-bottom: 100px;

    @media (max-width: 767px) {
        padding-bottom: 50px;

    }
}

.comparison-post {
    background-color: #ffffff;
    margin-bottom: 30px;
}

@media (max-width: 992px) {
    .home-banner h2 {
        font-size: 40px;
    }

    .home-banner-02 .home-efftect {
        background: #ffffff;
    }
}

@media (max-width: 767px) {
    .home-banner {
        h2 {
            font-size: 30px;
            margin: 0 0 20px;
        }

        p {
            font-size: 14px;
        }
    }

    .home-banner-01 h2, .home-banner-02 h2 {
        font-size: 30px;
    }
}

/*-----------------------------------------
*  Feature Box
-----------------------------------------*/

.feature-box {
    border: 1px solid #eeeeee;
    padding: 10%;
    -webkit-transition: ease all 0.3s;
    transition: ease all 0.3s;

    &:not(:hover) {
        background: #ffffff;
    }

    .icon {
        i {
            font-size: 35px;
        }

        margin: 0 0 20px;
    }

    .feature-content {
        h4 {
            font-size: 18px;
            font-weight: 600;
            margin: 0 0 15px;
        }

        p {
            margin: 0;
        }
    }

    &:hover {
        transform: translateY(-10px);

        .feature-content h4, .icon i {
            color: #ffffff;
        }

        .feature-content p {
            color: rgba(255, 255, 255, 0.8);
        }
    }
}

.feature-slider:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    border-top: 1px dashed #cccccc;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: -1;
}

.feature-box-01 {
    border: 1px dashed #cccccc;
    padding: 10%;
    margin: 15px 15px 25px;
    background: #ffffff;
    -webkit-transition: ease all 0.3s;
    transition: ease all 0.3s;

    .icon {
        i {
            font-size: 35px;
        }

        margin: 0 0 20px;
    }

    .feature-content {
        h4 {
            font-size: 20px;
            font-weight: 600;
            margin: 0 0 10px;
        }

        p {
            margin: 0;
        }
    }
}

/*-----------------------------------------
*  Video Section
-----------------------------------------*/
.video-section {
    position: relative;
    z-index: 2;
}

.video-section:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background: #000000;
    opacity: 0.5;
}

.video-box {
    text-align: center;
    position: relative;
    z-index: 1;
    padding: 5% 0;

    h6 {
        color: #ffffff;
        font-size: 18px;
        font-weight: 500;
    }

    h2 {
        color: #ffffff;
        font-size: 58px;
        font-weight: 500;
        margin: 15px 0 35px;
    }

    .icon {
        width: 70px;
        height: 70px;
        border: 2px solid #ffffff;
        display: inline-block;
        font-size: 22px;
        line-height: 66px;
        color: #ffffff;
        border-radius: 50%;
    }
}

@media (max-width: 992px) {
    .video-box h2 {
        font-size: 45px;
    }
}

@media (max-width: 767px) {
    .video-box h2 {
        font-size: 35px;
    }
}

.videos {
    padding: 90px 0 110px;
}

.videos-gallery {
    .owl-video-tn {
        background-size: 100% auto !important;
    }

    .owl-video-play-icon {
        opacity: .5;
        background: url('/assets/img/owl.video.play.png') no-repeat !important;
        transition: 0.3s all !important;
    }

    .owl-video-tn {
        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: #000;
            opacity: 0.7;
            transition: 0.3s all !important;
        }
    }

    .owl-video.popup-youtube {
        display: flex !important;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        color: #ffffff;
        align-items: flex-end;
        padding: 0 0 20px 15px;
        font-size: 18px;

        &:hover {
            ~ .owl-video-play-icon {
                opacity: 1;
                transform: scale(0.9) !important;
            }

            ~ .owl-video-tn {
                &:after {
                    opacity: 0.5;
                }
            }
        }
    }

    .owl-dots {
        position: absolute;
        bottom: -30px;
        left: 50%;
        transform: translateX(-50%);
    }

    .owl-next {
        right: 24%;
        @media (max-width: 1100px) {
            right: 5%;
        }
        @media (max-width: 767px) {
            right: 15%;
        }
        @media (max-width: 420px) {
            right: 12%;
        }
    }

    .owl-prev {
        left: 24%;
        @media (max-width: 1100px) {
            left: 5%;
        }
        @media (max-width: 767px) {
            left: 15%;
        }
        @media (max-width: 420px) {
            left: 12%;
        }
    }

    .owl-prev, .owl-next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        color: #fff;
        font-size: 24px;
    }
}

.videos-gallery-slide {
    height: 18vw;
    @media (max-width: 1500px) {
        height: 25vw;
    }
    @media (max-width: 767px) {
        height: 200px;
    }
}

.productGallery {
    background: #dae3fe;
    padding: 90px 0;
    color: #ffffff;
    @media (max-width: 769px) {
        padding: 60px 0;
    }

    .owl-dots {
        margin-top: 20px;
    }

    .owl-dot span {
        border: none;
        width: 12px;
        height: 12px;
    }

    .owl-stage-outer {
        z-index: 2;
    }

    .owl-nav {
        z-index: 1;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 22px;
        @media (max-width: 769px) {
            display: none;
        }
    }
}

.productGallery-title {
    text-align: center;
    margin-bottom: 10px;
    color: #000000;
}

.productGallery-title-head {
    font-weight: 700;
}

.productGallery-slider {
    padding: 0 40px;
    position: relative;
    @media (max-width: 769px) {
        padding: 0;
    }
}

.productGallery-slider-item {
    padding: 7px;
}

.productGallery-slider-item-img {
    box-shadow: 1px 1px 7px #666666;

    img {
        display: block;
        width: 100%;
        height: auto;
    }
}

/*-----------------------------------------
*  Price Table
-----------------------------------------*/

.price-table {
    border: 1px solid #eeeeee;
    border-radius: 5px;
    text-align: center;
    background: #ffffff;
    -webkit-transition: ease all 0.3s;
    transition: ease all 0.3s;

    &:hover {
        margin-top: -10px;
    }

    &.active .pt-head {
        p, h6 {
            color: rgba(255, 255, 255, 0.8);
        }

        h4 {
            color: #ffffff;
        }
    }

    .pt-head {
        padding: 25px 20px;
        border-bottom: 1px solid #eeeeee;

        h4 {
            font-size: 70px;
            font-weight: 700;
            line-height: 70px;

            span {
                font-size: 16px;
                vertical-align: text-top;
            }
        }

        p {
            margin: 0;
        }
    }

    .pt-body {
        padding: 20px;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    li {
        padding: 8px 0;

        b {
            font-weight: 600;
        }

        &.pt-no {
            opacity: 0.8;
            text-decoration: line-through;
        }
    }

    .pt-action {
        padding: 20px;
        border-top: 1px solid #eeeeee;
    }
}

/*-----------------------------------------
*  Testimonial
-----------------------------------------*/

.testimonial {
    background: #ffffff;
    border: 1px solid #eeeeee;
    margin: 1px 1px 20px;
    padding: 20px;
    border-radius: 5px;

    .testimonial-content {
        padding-top: 20px;

        p {
            margin: 0;
        }
    }

    .testimonial-info {
        width: 100%;
        display: inline-block;
        vertical-align: top;
    }

    .avtar {
        width: 50px;
        float: left;
        height: 50px;
        overflow: hidden;
        border-radius: 50%;
    }

    .name {
        width: calc(100% - 50px);
        float: left;
        padding: 5px 0 0 15px;

        span {
            display: block;
            font-weight: 500;
            color: #333333;
            line-height: normal;
        }

        label {
            font-size: 13px;
            margin: 0;
        }
    }
}

.testimonial-01 {
    p {
        font-size: 20px;
        font-weight: 500;
        margin: 0 0 20px;
    }

    label {
        margin: 0;
        font-size: 16px;
    }
}

/*-----------------------------------------
*  Counter
-----------------------------------------*/

.counter-col {
    text-align: center;
    padding: 15px 0;

    .count {
        font-size: 50px;
        font-weight: 700;
        line-height: 50px;
        margin: 0 0 15px;
    }

    h6 {
        font-weight: normal;
        color: #333333;
    }
}

/*-----------------------------------------
*  Blog
-----------------------------------------*/

.blog-item {
    .blog-img {
        border-radius: 5px;
        overflow: hidden;
        position: relative;
        top: 0;
        -webkit-transition: ease all 0.3s;
        transition: ease all 0.3s;
    }

    .blog-content {
        padding: 15px 0 0;
    }

    .post-meta {
        color: #333333;
        font-size: 13px;
    }

    h4 {
        margin: 8px 0 20px;
        font-size: 18px;
    }

    &:hover .blog-img {
        box-shadow: 0 8px 50px -6px rgba(84, 84, 120, 0.26);
        top: -10px;
    }

    p {
        margin: 0;
    }

    .btn-bar {
        margin-top: 18px;
    }
}

/*Blog Listing*/

.blog-lising .blog-item {
    margin-bottom: 40px;
    height: calc(100% - 30px);
}

.bottom-pagination {
    margin-top: 20px;
}

.sidebar-widget {
    margin-bottom: 40px;
    padding: 20px;
    background: #ffffff;
    border-style: solid;
    border-color: #e5e8ed;
    border-width: 1px 1px 3px 1px;
    border-radius: 5px;
    overflow: hidden;

    p {
        font-size: 15px;
        line-height: normal;

        &:last-child {
            margin: 0;
        }
    }

    .widget-title {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 25px;
        position: relative;
        border-bottom: 1px solid #e5e8ed;
        padding: 0 0 10px;
        color: #323232;

        &:after {
            content: "";
            width: 50px;
            height: 3px;
            position: absolute;
            bottom: -1px;
            left: 0;
        }
    }
}

.widget-search {
    form {
        position: relative;
    }

    .form-control {
        padding-right: 45px;
        border-radius: 0;
        box-shadow: none;
        height: 45px;
        border: 1px solid #e5e8ed;
        font-size: 14px;
    }

    .btn {
        position: absolute;
        right: 0;
        top: 0;
        height: 45px;
        width: 45px;
        text-align: center;
        line-height: 45px;
        border: none;
        border-radius: 3px;
        padding: 0;
        font-size: 22px;
    }
}

.widget-about .avtar {
    margin-bottom: 15px;
}

.widget-newsletter {
    .newsletter-box {
        position: relative;

        .form-control {
            padding-right: 45px;
            border-radius: 0;
            box-shadow: none;
            height: 45px;
            border: 1px solid #e5e8ed;
            font-size: 14px;
        }
    }

    .btn {
        position: absolute;
        right: 0;
        top: 0;
        height: 45px;
        width: 45px;
        text-align: center;
        line-height: 45px;
        border: none;
        border-radius: 3px;
        padding: 0;
    }
}

.bog-content-area, .blog-comment-area {
    margin-bottom: 40px;
    position: relative;
    background: #ffffff;
    padding: 20px;
    border-style: solid;
    border-color: #e5e8ed;
    border-width: 1px 1px 3px 1px;
    border-radius: 5px;
    overflow: hidden;
}

.blog-tag-nav {
    margin-bottom: 40px;
    border-style: solid;
    border-color: #e5e8ed;
    border-width: 1px 1px 3px 1px;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    background: #ffffff;
    padding: 20px;

    .social-icons {
        margin: 0;
        padding: 0;

        li {
            display: inline-block;
            vertical-align: middle;
            margin-left: 8px;

            a {
                display: block;
                padding: 0;
                color: #7f7c97;
                width: 30px;
                height: 30px;
                border: 1px dashed #e5e8ed;
                text-align: center;
                line-height: 30px;
                border-radius: 0;
                background: transparent;
                -webkit-transition: ease all 0.3s;
                transition: ease all 0.3s;

                &:hover {
                    color: #323232;
                    background: #ffffff;
                    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
                }
            }
        }
    }
}

.pagination {
    margin: 30px 0 0 0;
    list-style: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.pagination-link {
    font-size: 15px;
    font-weight: 500;
    margin: 0 5px;
    color: #626262;
    padding: 0 6px;
    border-radius: 3px;
    background: #ffffff;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);

    &:focus, &:hover, &--active {
        background: #8068bc;
        color: #fff;
    }
}


.pagination-link-line {
    font-size: 15px;
    font-weight: 500;
    margin: 0 5px;
    color: #626262;
    padding: 0 6px;
    border-radius: 9px;
    background: #c4c4c4;
    width: 70px;
    height: 7px;

    &:focus, &:hover, &--active {
        background: #1dbc00;
        // color: #fff;
    }

    @media (max-width: 992px) {
        width: 20px;
        height: 20px;
    }
}


.page-item.active .page-link {
    background: #ffffff;
    color: #323232;
}

.title-style-1 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 25px;
    position: relative;
    border-bottom: 1px solid #e5e8ed;
    padding: 0 0 10px;
    color: #323232;

    &:after {
        content: "";
        width: 50px;
        height: 3px;
        position: absolute;
        bottom: -1px;
        left: 0;
    }
}

.blog-comment {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        padding: 30px 0;
        border-bottom: 1px dashed #e5e8ed;
        position: relative;

        .btn-reply {
            float: right;
            border: 1px solid #ededed;
            padding: 0 8px;
            font-size: 10px;
            font-weight: 600;
            position: relative;
        }

        &:last-child {
            border-bottom: none;
            padding-bottom: 0;
        }

        ul.child-comment {
            margin-left: 40px;
            list-style: none;
            padding: 0;

            li {
                border: none;
                padding-bottom: 0;
                border-top: 1px dashed #e5e8ed;
                margin-top: 30px;
            }
        }

        .person {
            font-weight: 600;
            color: #323232;
        }

        .date {
            font-style: italic;
            display: block;
            font-size: 12px;
            line-height: normal;
            margin-top: 5px;
        }

        .reply {
            position: absolute;
            top: 0;
            right: 15px;
            font-size: 10px;
            border: 1px solid #323232;
            line-height: normal;
            color: #ffffff;
            padding: 3px 8px;
            text-decoration: none;
            background: #323232;
            text-transform: uppercase;

            &:after {
                content: "";
                border-bottom: 9px solid transparent;
                border-left: 8px solid #696969;
                position: absolute;
                bottom: -10px;
                right: 0;
            }
        }

        p {
            margin: 15px 0 0;
        }
    }

    > li:first-child {
        padding-top: 0;
    }
}

.blog-comment-form {
    position: relative;
    background: #ffffff;
    padding: 20px;
    border-style: solid;
    border-color: #e5e8ed;
    border-width: 1px 1px 3px 1px;
    border-radius: 5px;
    overflow: hidden;

    .form-group {
        label {
            font-weight: 500;
            font-size: 14px;
            line-height: normal;
            color: #323232;
        }

        .form-control {
            border-radius: 0;
            box-shadow: none;
            font-size: 14px;
            border: 1px solid #dcdcdc;
            color: #000000;
        }
    }
}

blockquote.blockquote-left {
    background: #f9f9f9;
    padding: 20px;
    border-left: 5px solid #bdbdbd;
    font-size: 16px;
    margin: 30px 0 50px;

    .parson {
        font-weight: 600;
    }
}

.latest-post {
    margin: 0;
    padding: 0;

    li {
        width: 100%;
        display: inline-block;
        vertical-align: top;

        &:not(:last-child) {
            padding: 0 0 18px 0;
            margin: 0 0 18px 0;
            border-bottom: 1px dashed #e5e8ed;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .lp-img {
        width: 60px;
        float: left;
    }

    .lp-text {
        overflow: hidden;
        line-height: 14px;
        padding-left: 10px;

        h6 {
            display: inline-block;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin: 0 0 3px;
        }

        a {
            font-size: 14px;
            color: #555555;
        }
    }

    .date {
        font-size: 13px;
    }
}

.list-style-tag {
    margin: 0;
    padding: 0;

    li {
        display: inline-block;
        vertical-align: top;

        a {
            position: relative;
            padding: 6px 12px;
            line-height: 20px;
            font-weight: 400;
            border: 1px dashed #e5e8ed;
            margin-right: 4px;
            margin-bottom: 3px;
            display: inline-block;
            vertical-align: top;
            color: #323232;
            font-size: 14px;
            margin-top: 3px;

            &:hover {
                background: #ffffff;
                box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
                text-decoration: none;
            }
        }
    }
}

.list-style-1 {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        position: relative;
        padding: 0 0 8px 0;
        margin: 0 0 8px 0;
        border-bottom: 1px dashed #e5e8ed;

        span {
            position: absolute;
            top: 3px;
            right: 0;
        }

        &:last-child {
            border-bottom: none;
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }

    a {
        color: #555555;
        text-decoration: none;

        &:hover {
            color: #323232;
        }
    }
}

/*-----------------------------------------
*  Tabs
-----------------------------------------*/

.tab-style-1 {
    .nav-link {
        display: block;
        padding: 20px 35px;
        box-shadow: 0 -10px 14px rgba(0, 0, 0, 0.06);
        text-transform: uppercase;
        background: #fdfdfd;
        position: relative;
        font-weight: 500;

        &:not(.active) {
            color: #111111;
        }

        &:after {
            width: 0;
            height: 5px;
            position: absolute;
            top: -5px;
            content: "";
            left: 0;
            transition: ease all 0.3s;
            -webkit-transition: ease all 0.3s;
        }

        &.active {
            background: #ffffff;
            position: relative;
            z-index: 1;
            box-shadow: 0px -12px 20px rgba(0, 0, 0, 0.12);

            &:after {
                width: 100%;
            }
        }
    }

    .tab-content {
        position: relative;
    }

    .tab-pane {
        box-shadow: 0 6px 30px rgba(0, 0, 0, 0.12);
        padding: 60px 40px;
        background: #ffffff;
    }

    .owl-carousel {
        border: 1px solid #eeeeee;
    }

    .owl-dots {
        position: absolute;
        bottom: 10px;
        width: 100%;
        text-align: center;
    }
}

@media (max-width: 992px) {
    .tab-style-1 .tab-pane {
        padding: 30px 20px;
    }
}

/*-----------------------------------------
*  Team
-----------------------------------------*/

.our-team {
    background: #ffffff;
    position: relative;
    overflow: hidden;
    height: 100%;

    .team-avtar {
        position: relative;
        overflow: hidden;
        display: flex;
        align-items: flex-start;
        height: 359px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            opacity: 0;
            transition: ease all 0.3s;
            -webkit-transition: ease all 0.3s;
        }

        &--demostands {
            max-height: 265px;
            height: auto;

            img {
            width: 100%;
            height: 100%;
            }
        }
    }

    &:hover .team-avtar:after {
        opacity: 0.6;
    }

    .team-info {
        text-align: center;
        padding: 15px;
        position: relative;

        h6 {
            font-size: 16px;
            font-weight: 600;
            margin: 0 0 5px;
        }

        label {
            font-size: 13px;
            color: #333333;
            margin: 0;
        }
    }

    .social-icons {
        margin: 0;
        padding: 0;
        position: absolute;
        bottom: -30px;
        left: 0;
        width: 100%;
        z-index: 2;
        opacity: 0;
        transition: ease all 0.3s;
        -webkit-transition: ease all 0.3s;
        text-align: center;

        li {
            width: 35px;
            list-style: none;
            margin: 0 3px;
            position: relative;
            bottom: 0;
            transition: ease all 0.3s;
            -webkit-transition: ease all 0.3s;
            display: inline-block;

            a {
                width: 35px;
                height: 35px;
                background: #ffffff;
                display: block;
                border-radius: 50%;
                line-height: 35px;
                transition: ease all 0.3s;
                -webkit-transition: ease all 0.3s;
                text-align: center;

                &:hover {
                    background: #222222;
                    color: #ffffff;
                    box-shadow: 0 8px 50px -6px rgba(84, 84, 120, 0.26);
                }
            }
        }
    }

    &:hover .social-icons {
        opacity: 1;
        bottom: 10px;

        li {
            top: 0;
        }
    }
}

.large-team .team-info {
    padding: 20px;
    text-align: left;

    h5 {
        font-weight: 600;
        margin: 0 0 15px;
    }

    p {
        margin: 0;
    }
}

/*-----------------------------------------
*  Counter
-----------------------------------------*/

.section-contact-down {
    border-bottom: 100px solid #111111;

    .contact-form-down {
        margin-bottom: -100px;
        border-radius: 5px;
        padding: 8% 10%;
    }
}

.output_message {
    vertical-align: top;
    margin-top: 15px;
    padding: 4px 10px;
    float: left;
    width: 100%;
    font-size: 13px;
    text-align: center;
    letter-spacing: .5px;
    border-radius: 3px;

    &.success {
        background: #25a224;
        display: block;
        color: #ffffff;
    }

    &.error {
        background: #dc1e1e;
        display: block;
        color: #ffffff;
    }
}

.form-group {
    position: relative;
    width: 100%;
    margin-bottom: 25px;

    * {
        cursor: text;
    }

    .form-control {
        outline: none;
        box-shadow: none;
        height: 40px;
        border: 1px solid #d4d4d4;
        width: 100%;
        font-size: 14px;
        color: #000000;
        background: #f7fafc;

        &.form-xl {
            height: 55px;
            border: 1px solid #dddddd;
            font-size: 16px;
            border-bottom: 2px solid #dddddd;
        }

        &.error {
            border-color: #ffa3a3;
            margin-bottom: 10px;
        }

        &::placeholder {
            opacity: 0;
        }

        &:not(:placeholder-shown), &:focus {
            &::placeholder {
                opacity: 0.5;
            }

            ~ .form-group-info {
                opacity: .2;
            }

            ~ .input-label {
                transform: translate(-6px, -28px) scale(0.8);
            }
        }
    }

    textarea.form-control {
        height: 135px;

        &.form-xl {
            height: 170px;
        }
    }

    .input-focus-effect {
        width: 0 !important;
        position: absolute;
        bottom: 0;
        left: 0;
        -webkit-transition: ease all 0.3s;
        transition: ease all 0.3s;
        height: 2px;
    }

    textarea:focus:not([readonly]) + span.input-focus-effect, input:focus:not([readonly]) + span.input-focus-effect, select:focus:not([readonly]) + span.input-focus-effect {
        width: 100% !important;
    }

    label.error {
        position: absolute;
        font-size: 11px;
        color: red;
        top: calc(100% - 12px);
        left: 5px;
        letter-spacing: -0.3px;
    }

    input.error {
        ~ .form-group-info {
            bottom: 8px;
        }
    }

    .input-label {
        position: absolute;
        left: 0;
        top: 8px;
        padding: 0 .75rem;
        transition: 0.2s transform;
        transform-origin: 0 50%;
    }
}

.form-group-info {
    font-size: 11px;
    position: absolute;
    bottom: -2px;
    right: 3px;
    letter-spacing: 0;
    transition: 0.2s opacity;

    b {
        color: red;
    }
}

.contact-form {
    box-shadow: 0 8px 50px -6px rgba(84, 84, 120, 0.26);
    background: #ffffff;
    padding: 20px;
}

/*-----------------------------------------
*  Footer
-----------------------------------------*/
.footer {
    margin-top: auto;
}

.footer-dark {
    background: #111111;
}

.footer-section {
    padding-top: 80px;

    h4 {
        color: #ffffff;
        font-size: 18px;
        font-weight: 600;
        position: relative;
        padding: 0 0 10px;
        margin: 0 0 15px;

        &:after {
            content: "";
            width: 25px;
            height: 2px;
            background: #ffffff;
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }

    .social-icons {
        margin: 20px 0 0 0;
        padding: 0;

        li {
            display: inline-block;
            vertical-align: top;
            margin-right: 8px;

            a {
                display: block;
                padding: 0;
                color: #ffffff;
                width: 30px;
                height: 30px;
                text-align: center;
                line-height: 30px;
                font-size: 12px;
                background: rgba(255, 255, 255, 0.12);
                -webkit-transition: ease all 0.3s;
                -moz-transition: ease all 0.3s;
                -o-transition: ease all 0.3s;
                transition: ease all 0.3s;
                border-radius: 5px;

                &:hover {
                    background: rgba(0, 0, 0, 0.8);
                    color: #ffffff;
                }
            }
        }
    }

    .fot-link {
        margin: 0;
        padding: 0;

        li {
            width: 100%;
            display: inline-block;
            vertical-align: top;
            margin-bottom: 5px;
            font-size: 12px;
        }

        a {
            color: rgba(255, 255, 255, 0.61);
            font-size: 14px;
            position: relative;
            text-decoration: none;
            font-weight: 400;

            &:hover {
                color: #ffffff;
            }

            &:after {
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                width: 0;
                height: 1px;
                background: rgba(255, 255, 255, 0.3);
                -webkit-transition: ease all 0.3s;
                -moz-transition: ease all 0.3s;
                -o-transition: ease all 0.3s;
                transition: ease all 0.3s;
            }

            &:hover:after {
                width: 100%;
            }
        }
    }

    .footer-text {
        width: 310px;
        max-width: 100%;
    }

    p {
        margin: 0;
        color: rgba(255, 255, 255, 0.64);
    }
}

.fot-address {
    user-select: none;
    font-family: Montserrat;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #878787;

    h4.font-alt {
        font-family: Montserrat;
        font-weight: 400;
        font-size: 19px !important;

        @media (max-width: 770px) {
            font-size: 15px !important;
            text-align: center;
            border-top: 1px solid #ececec;
        }
    }
}

.fot-address p {
    position: relative;
    padding-left: 28px;
    margin-bottom: 15px;

    i {
        position: absolute;
        top: 4px;
        left: 0;
        color: #878787;
        font-size: 18px;
    }

    @media (max-width: 770px) {
        margin-bottom: 2px;
    }

     a {
        color: #878787;
        font-size: 16px;
     }
}

.footer-copy {
    margin-top: 70px;
    border-top: 1px solid rgba(255, 255, 255, 0.07);
    padding: 15px 0;
    text-align: center;

    p {
        margin: 0;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.47);
        font-weight: 300;
    }

    @media (max-width: 770px) {
        margin-bottom: 2px;
    }
}

@media (max-width: 992px) {
    .footer-section {
        padding-top: 40px;
    }

    .footer-copy {
        margin-top: 30px;
    }
}

@media (max-width: 767px) {
    .footer-section {
        padding-top: 30px;
    }

    .footer-copy {
        margin-top: 20px;
    }
}

.footer-light {
    background: #ffffff;
    border-top: 1px solid #dddddd;

    .footer-section {
        h4 {
            color: #323232;
        }

        p {
            color: rgba(0, 0, 0, 0.75);
        }

        .social-icons li a {
            color: rgba(0, 0, 0, 0.75);
            background: rgba(88, 88, 88, 0.12);
        }

        .fot-link a {
            color: rgba(0, 0, 0, 0.75);
        }
    }

    .footer-copy {
        border-top: 1px solid rgba(0, 0, 0, 0.2);
    }
}

.compare {
    color: #000000;
    font-family: Roboto;

    > .container {
        padding-top: 160px;
        position: relative;
        @media (max-width: 767px) {
            padding-top: 120px;
        }
        @media (min-width: 1200px) {
            max-width: 1250px;
        }

        &:after {
            content: '';
            width: 382px;
            height: 362px;
            background-image: url('../img/compare-watermark.png');
            background-size: 100% 100%;
            position: absolute;
            right: -80px;
            top: 0;
        }
    }
}

.compare-title {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__head {
        font-weight: 700;
        font-size: 54px;
        line-height: 63px;
        margin-bottom: 20px;
        @media (max-width: 992px) {
            font-size: 42px;
            line-height: normal;
        }
        @media (max-width: 767px) {
            font-size: 36px;
        }
    }

    &__subhead {
        font-size: 24px;
        line-height: 28px;
        max-width: 800px;
        @media (max-width: 992px) {
            font-size: 22px;
            line-height: normal;
        }
        @media (max-width: 767px) {
            font-size: 18px;
        }
    }
}

.compare-content {
    margin-top: 100px;
    @media (max-width: 992px) {
        margin-top: 70px;
    }
}

.compare-table {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    &:not(:first-child) {
        margin-bottom: 80px;
    }

    &__title {
        font-weight: 700;
        margin-bottom: 10px;
        font-size: 18px;
        line-height: normal;
        @media (max-width: 992px) {
            font-size: 16px;
        }
    }

    &--style1 {
        .compare-col {
            &:nth-child(2) {
                background-color: rgba(#FDCD56, 0.08);
            }
        }

        .compare-row {
            &:first-of-type {
                .compare-col {
                    &:nth-child(2) {
                        border-radius: 8px 8px 0 0;
                    }
                }
            }

            &:last-of-type {
                .compare-col {
                    &:nth-child(2) {
                        border-radius: 0 0 8px 8px;
                    }
                }
            }
        }
    }

    &--style2 {
        .compare-col {
            &:nth-child(2) {
                background-color: rgba(#44B991, 0.08);
            }
        }

        .compare-row {
            &:first-of-type {
                .compare-col {
                    &:nth-child(2) {
                        border-radius: 8px 8px 0 0;
                    }
                }
            }

            &:last-of-type {
                .compare-col {
                    &:nth-child(2) {
                        border-radius: 0 0 8px 8px;
                    }
                }
            }
        }
    }

    &--style3 {
        .compare-col {
            &:nth-child(2) {
                background-color: rgba(#8068BC, 0.08);
            }
        }

        .compare-row {
            &:first-of-type {
                .compare-col {
                    &:nth-child(2) {
                        border-radius: 8px 8px 0 0;
                    }
                }
            }

            &:last-of-type {
                .compare-col {
                    &:nth-child(2) {
                        border-radius: 0 0 8px 8px;
                    }
                }
            }
        }
    }

    &--logos {
        @media (max-width: 767px) {
            margin: 0 0 10px;
            .compare-col {
                flex-direction: column;
                align-items: center;
                padding: 0 !important;

                &:nth-child(2) {
                    align-items: flex-start;
                    background: none !important;

                    &:after {
                        content: 'VS';
                        text-align: center;
                        font-size: 32px;
                        display: flex !important;
                        justify-content: center;
                        position: static;
                        width: 100%;
                        background: none;
                        margin: 15px 0 5px 0;
                    }
                }

                &:nth-child(3) {
                    align-items: flex-end;
                    background: none !important;
                }
            }
        }
    }
}

.compare-row {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    @media (max-width: 767px) {
        flex-wrap: wrap;
    }
}

.compare-col {
    width: calc(33% - 25px);
    display: flex;
    align-items: flex-start;
    line-height: 19px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 16px;
    @media (max-width: 992px) {
        font-size: 14px;
        padding-top: 10px;
        padding-bottom: 10px;
        width: calc(33% - 15px);
    }
    position: relative;
    @media (max-width: 767px) {
        width: 100%;
        padding: 20px 15px 15px 15px !important;
        border-radius: 0 !important;
        &:nth-child(2), &:nth-child(3) {
            &:after {
                content: '';
                position: absolute;
                width: 70px;
                height: 18px;
                right: 5px;
                top: 5px;
                background-size: 100% auto;
                background-image: url('../img/optimacros-logo.png');
                opacity: .8;
            }
        }

        &:nth-child(2) {
            background: rgba(#FDCD56, 0.08) !important;
        }

        &:nth-child(3) {
            background: rgba(#44B991, 0.08) !important;

            &:after {
                background-image: url('../img/anaplan-logo.png');
                height: 23px;
            }
        }
    }

    &:nth-child(2n) {
        padding-left: 15px;
        padding-right: 15px;
        @media (max-width: 992px) {
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    &__icon {
        margin-right: 15px;
        @media (max-width: 992px) {
            width: 25px;
            height: 25px;
        }
    }

    &__text {
        padding-top: 8px;
        @media (max-width: 992px) {
            padding-top: 6px;
        }
    }

    &:not(:first-child) {
        font-size: 14px;
        line-height: 16px;
        @media (max-width: 992px) {
            font-size: 12px;
            line-height: 14px;
        }
    }

    &--logo {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 25px 0;

        &:after {
            display: none !important;
        }
    }
}

.compareFooter {
    padding: 0 0 100px 0;
    color: #000000;
    text-align: center;

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    a {
        color: #cd6ba7;
    }
}

.compareFooter-title {
    max-width: 385px;
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;

    a {
        &:hover {
            text-decoration: underline;
        }
    }
}

.compareFooter-subtitle {
    font-size: 18px;

    a {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}

.blog {
    background: #f7f7f7;
    padding: 90px 0 40px;
}

.blog-container, .innerContent-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @media (max-width: 992px) {
        flex-direction: column;
    }
}

.blog-content {
    width: calc(100% - 380px);
    @media (max-width: 992px) {
        width: 100%;
        margin-bottom: 20px;
    }
}

.blog-aside {
    max-width: 360px;
    width: 100%;
    flex-shrink: 0;
    @media (max-width: 992px) {
        max-width: 100%;
    }
}

.blog-aside-box {
    padding: 20px;
    background: #fff;

    &:not(:last-child) {
        margin-bottom: 20px;
    }
}

.blog-aside-title {
    margin-bottom: 15px;
}

.blog-aside-category {
    display: flex;
    flex-direction: column;
}

.blog-aside-tags {
    display: flex;
    flex-wrap: wrap;
    margin-top: -5px;
}

.blog-aside-tags-link {
    padding: 0 8px;
    border: 1px solid #8068bc;
    margin: 5px 0;
    border-radius: 3px;

    &:not(:last-child) {
        margin-right: 10px;
    }

    &--active {
        background: #8068bc;
        color: #fff;
    }
}

.post {
    padding: 20px 20px 30px;
    background: #fff;

    &:not(:last-child) {
        margin-bottom: 20px;
    }
}

.post-meta {
    display: flex;
    justify-content: center;
    padding-bottom: 5px;
    margin-top: -10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e6e6e6;
    font-size: 12px;

    i {
        margin-right: 3px;
    }
}

.post-meta-link {
    &:not(:last-child) {
        margin-right: 20px;
    }

    color: #878c90;
}

.post-link {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.blog-aside-category-link {
    padding: 5px 0;
    line-height: 1.3;
    color: #6a6f73;

    &:not(:last-child) {
        border-bottom: 1px solid #d2d2d2;
        margin-bottom: 5px;
    }
}

.innerContent {
    padding: 90px 0 40px;
    background: #f7f7f7;
}

.innerContent-content {
    background-color: #fff;
    padding: 20px;
}

.innerContent-bottom {
    padding: 20px 20px 50px;
}

.g_format {
    > * {
        &:last-child {
            margin-bottom: 0 !important;
        }
    }

    h1 {
        font-size: 56px;
        line-height: 64px;
        @media (max-width: 767px) {
            font-size: 36px;
            line-height: 44px;
        }
    }

    h2 {
        font-size: 36px;
        line-height: 1.22;
        margin-bottom: 16px;
        @media (max-width: 767px) {
            font-size: 32px;
            margin-bottom: 8px;
        }
    }

    h3 {
        font-size: 30px;
        line-height: 1.22;
        margin-bottom: 16px;
        @media (max-width: 767px) {
            font-size: 24px;
            font-weight: 700;
        }
    }

    h4 {
        font-size: 24px;
        line-height: 1.22;
        margin-bottom: 16px;
        @media (max-width: 767px) {
            font-size: 20px;
        }
    }

    h5 {
        font-size: 22px;
        line-height: 1.22;
        margin-bottom: 16px;
        @media (max-width: 767px) {
            font-size: 18px;
        }
    }

    h6 {
        font-size: 20px;
        line-height: 1.22;
        margin-bottom: 16px;
        @media (max-width: 767px) {
            font-size: 16px;
        }
    }

    p {
        &:not(:last-child) {
            margin-bottom: 20px;
            @media (max-width: 767px) {
                margin-bottom: 10px;
            }
        }

        + h1, + h2, + h3, + h4, + h5, + h6 {
            padding-top: 10px;
        }
    }

    ol {
        margin: 0 0 30px 0;
        padding-left: 10px;
        list-style: decimal outside;

        li {
            margin-left: 18px;

            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    }

    ul {
        margin: 0 0 30px 0;
        padding-left: 10px;
        list-style: disc outside;

        li {
            margin-left: 18px;

            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    }

    img {
        max-width: 100%;
    }

    figure {
        text-align: center;
        margin-bottom: 30px;
        @media (max-width: 767px) {
            text-align: left;
            margin-bottom: 30px;
        }
    }

    figcaption {
        font-size: 14px;
        color: #757575;
        font-style: italic;
    }

    > img {
        margin-bottom: 40px;
    }

    blockquote {
        position: relative;
        padding: 8px 0 8px 40px;
        margin-bottom: 25px;
        @media (max-width: 767px) {
            padding-left: 32px;
        }

        &:before {
            content: '';
            position: absolute;
            width: 8px;
            background-color: #8068bc;
            left: 0;
            top: 0;
            height: 100%;
        }

        p {
            &:not(:last-child) {
                margin-bottom: 15px;
            }
        }
    }

    table {
        font-size: 16px;
        margin-bottom: 30px;

        thead {
            font-weight: 600;
        }

        tr {
            border-bottom: 1px solid #e0e0e0;
        }

        td {
            padding: 8px 0;
        }

        caption {
            color: #757575;
            font-size: 14px;
            line-height: 26px;
            font-style: italic;

            &[align="bottom"] {
                margin-top: 10px;
            }

            &[align="top"] {
                margin-bottom: 10px;
            }
        }
    }

    .table-scroll {
        margin-bottom: 20px;
        overflow: auto;
        max-width: 100%;

        table {
            margin-bottom: 0;
        }
    }
}


/*-----------------------------------------
*   Page 404
-----------------------------------------*/

#page-error {
    width: 100%;

    .error-box {
        height: 100%;
        position: fixed;
        top: 20%;
        width: 100%;
    }

    .error-body {
        padding-top: 5%
    }

    .error-body h1 {
        font-size: 150px;
        font-weight: 900;
        line-height: 210px
    }

    .text-danger {
        color: #0073f4 !important;
    }

    .btn-danger {
        background-color: #0073f4;
    }

    .m-b-40 {
        margin-bottom: 40px !important
    }

    @media only screen and (max-width: 520px) {
        .error-body h1 {
            font-size: 110px;
            font-weight: 700;
            line-height: 110px
        }
    }
}


.cc-bottom  {
    .cc-message {
        font-family: Pt Sans;
        font-size: 16px;
    }

    .cc-btn {
        font-family: Montserrat;
    }
}
/*-----------------------------------------
*  Background Properties
-----------------------------------------*/

.bg-no-repeat {
    background-repeat: no-repeat;
}

.bg-repeat-x {
    background-repeat: repeat-x;
}

.bg-repeat-y {
    background-repeat: repeat-y;
}

.bg-cover {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.bg-center-center {
    background-position: center center;
}

.bg-center-right {
    background-position: center right;
}

.bg-center-left {
    background-position: center left;
}

.bg-fixed {
    background-attachment: fixed;
}

::selection, ::-moz-selection {
    background-color: #8068bc;
    color: #ffffff;
}

a, .theme-color {
    color: #8068bc;
}

.mm-column li {
    &.mm-header > a, &:not(.mm-header) a:hover {
        color: #8068bc;
    }
}

.theme-before:before, .theme-after:after, .theme-hover:hover, .fixed-header .header-nav a.btn {
    background: #0073F4;
}

.theme-bg {
    background: #dae3fe;
}

.comparison-bg {
    background: linear-gradient(to top, #fff 10%, #EEF2FF 30%, #EEF2FF 70%, #fff 90%);
}

.btn-theme {
    background: #0073F4;
}

.theme-hover:hover {
    border-color: #0073F4;
}

.btn-theme.btn-theme--green {
    background: #1dbc00;
}

@media (max-width: 992px) {
    .header-nav a.btn {
        background: #8068bc;
    }
}

.mfp-iframe-holder .mfp-content {
    max-width: calc(100vw - 70px) !important;
    max-height: calc(100vw - 70px) !important;
    @media (max-width: 767px) {
        max-width: calc(100vw - 20px) !important;
        max-height: calc(100vw - 20px) !important;
    }
}




.comparison-first-head {
    background-image: url("../img/front/comparison-front.webp");
    background-color: #FAFAFA;
    // background: linear-gradient(to left, #ECECEC 40%, #FAFAFA 80%);
    background-repeat: no-repeat;
    background-size: contain;
    background-attachment: local;
    background-position: bottom right;
    min-height: 700px;

    @media (max-width: 1600px) {
        background-position-x: 650px;
        background-position-y: bottom;
        min-height: auto;
    }

    @media (max-width: 1400px) {
        background-position-x: 480px;
        background-position-y: bottom;
        min-height: auto;
    }

    @media (max-width: 1200px) {
        background-position-x: 480px;
        background-position-y: bottom;
        min-height: auto;
    }

    @media (max-width: 1199px) {
        min-height: auto;
        background: linear-gradient(to right, #FAFAFA, #f4f1f0);
    }

    @media (max-width: 769px) {
        min-height: auto;
        background: linear-gradient(to right, #FAFAFA, #f4f1f0);
    }

    .container {
        max-width: 1170px;
        padding: 0;

        @media (max-width: 1280px) {
            max-width: 90%;
        }
    }
}

.comparison-first-head-block {
    max-width: 65%;
    font-family: 'Montserrat';
    font-size: 60px;
    font-weight: 700;
    line-height: 78px;
    letter-spacing: 0em;
    text-align: left;
    color: #4373FF;
    padding-top: 150px;

    @media (max-width: 1200px) {
        max-width: 90%;
        padding-top: 100px;
        font-size: 38px;
        line-height: 50px;
    }

    @media (max-width: 992px) {
        max-width: 90%;
        padding-top: 100px;
        font-size: 38px;
        line-height: 50px;
    }

    @media (max-width: 769px) {
        // padding-top: 0;
        max-width: 90%;
        font-size: 30px;
        line-height: 40px;
    }
}

.comparison-first-head-text {
    font-family: Pt Sans;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #6A6A6A;
    padding-top: 20px;

    div {
        padding-top: 80px;
        padding-bottom: 80px;

        @media (max-width: 1200px) {
            padding-top: 40px;
            padding-bottom: 40px;
        }

        @media (max-width: 992px) {
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }

    a {
        width: 300px;
        vertical-align: bottom;
    }

    @media (max-width: 992px) {
        font-size: 18px;
        line-height: 26px;
        padding-top: 15px;
    }

    @media (max-width: 769px) {
        font-size: 16px;
        line-height: 20px;
    }
}




.comparison-bg {
    padding-bottom: 300px;
    margin-bottom: -300px;

    .container {
        max-width: 1170px;
        padding: 0;

        @media (max-width: 1280px) {
            max-width: 90%;
        }
    }
    
    .frontPage-header {
        
        display: flex;
        flex-direction: column;
        align-self: stretch;

        div.frontPage-header-text {
            font-family: PT Sans;
            font-size: 16px;
            font-weight: 400;
            line-height: 23px;
            margin: 30px 0 30px;
        }

        a {
            width: 300px;
            vertical-align: bottom;
            @media (max-width: 997px) {
                margin-bottom: 40px;
            }
        }
       
    }
    .header-block-media {
        padding-top: 8vh;
    }

    .blue-header {
        color:#0073F4;
    }
    .frontPage-video-block {
        margin-top: 0;
    }
}


.header-block-media {
    display: flex;
    justify-content: space-between;
    padding: 40px 0 60px;

    // @media (max-width: 1280px) {
    //     max-width: 90%;
    // }

    @media (max-width: 992px) {
        padding: 40px 0 40px;
        flex-direction: column;
    }
}